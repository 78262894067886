import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  CardMeta,
  CardHeader,
  CardContent,
  Card,
  Dimmer,
  Grid,
  Icon,
  Image,
  Input,
  ListItem,
  ListContent,
  List,
  Loader,
  Segment,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLoginCheck } from "../../hooks/useLoginCheck";

function CustomerListPage() {
  useLoginCheck();
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [customerDetail, setCustomerDetail] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [admissionFee, setAdmissionFee] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const searchInputRef = useRef(null);

  const handleDetail = async (customerId) => {
    setCustomerDetail({ id: 99999999 });
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_customer_details/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer_id: customerId,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setCustomerDetail(data);
      console.log(data);
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  };

  const fetchCustomersData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_customers/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setCustomers(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  useEffect(() => {
    fetchCustomersData();
  }, []);

  // 検索実行
  const handleSearch = (event) => {
    let keyword = searchKeyword.toLowerCase();

    if (
      event.type === "click" ||
      (event.key === "Enter" && event.target.value)
    ) {
      if (event.target.value) {
        keyword = event.target.value.toLowerCase();
      }

      const filtered = customers.filter(
        (customer) =>
          customer.id.toString().includes(keyword) ||
          customer.last_name.toLowerCase().includes(keyword) ||
          customer.first_name.toLowerCase().includes(keyword) ||
          `${customer.last_name}${customer.first_name}`
            .toLowerCase()
            .includes(keyword) ||
          `${customer.last_name} ${customer.first_name}`
            .toLowerCase()
            .includes(keyword) ||
          customer.last_name_yomi.toLowerCase().includes(keyword) ||
          customer.first_name_yomi.toLowerCase().includes(keyword) ||
          `${customer.last_name_yomi}${customer.first_name_yomi}`
            .toLowerCase()
            .includes(keyword) ||
          `${customer.last_name_yomi} ${customer.first_name_yomi}`
            .toLowerCase()
            .includes(keyword) ||
          customer.parent_last_name.toLowerCase().includes(keyword) ||
          customer.parent_first_name.toLowerCase().includes(keyword) ||
          customer.studio.toLowerCase().includes(keyword) ||
          customer.phone.includes(keyword) ||
          customer.mail.toLowerCase().includes(keyword) ||
          customer.start_month.includes(keyword)
      );
      setFilteredCustomers(filtered);
    }
  };

  // ショートカット
  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     // コマンド + F
  //     if (event.metaKey && event.key === "f") {
  //       // デフォルト機能をキャンセル
  //       event.preventDefault();
  //       // 検索窓にフォーカス
  //       if (searchInputRef.current) {
  //         searchInputRef.current.focus();
  //       }
  //     }
  //   };
  //   document.addEventListener("keydown", handleKeyDown);
  //   // クリーンアップ
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  // 入会処理
  const handleAdmission = async () => {
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/admission/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer_id: customerDetail.id,
          admission_fee: admissionFee,
        }),
      });
      if (!response.ok) {
        const data = await response.json();
        alert(data.message);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      fetchCustomersData();
      alert("入会処理が完了しました。");
      setIsLoading(false);
    } catch (error) {
      console.error("入会処理に失敗しました", error);
      setIsLoading(false);
    }
  };

  // 取消処理
  const handleCancel = async () => {
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/cancel_admission/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer_id: customerDetail.id,
        }),
      });
      if (!response.ok) {
        const data = await response.json();
        alert(data.message);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      fetchCustomersData();
      alert("取消処理が完了しました。");
      setIsLoading(false);
    } catch (error) {
      console.error("取消処理に失敗しました", error);
      setIsLoading(false);
    }
  };

  // 退会処理
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/delete_customer/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer_id: customerDetail.id,
        }),
      });
      if (!response.ok) {
        const data = await response.json();
        alert(data.message);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      fetchCustomersData();
      alert("退会処理が完了しました。");
      setIsLoading(false);
    } catch (error) {
      console.error("退会処理に失敗しました", error);
      setIsLoading(false);
    }
  };

  // 生徒一覧の初期表示
  useEffect(() => {
    setFilteredCustomers(customers);
  }, [customers]);

  // 生徒一覧の初期表示
  useEffect(() => {
    setAdmissionFee(customerDetail.admission_fee);
  }, [customerDetail]);

  return (
    <>
      <Dimmer active={isLoading}>
        <Loader size="medium"></Loader>
      </Dimmer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>生徒一覧</h1>
        <Input
          icon={{
            name: "search",
            circular: true,
            link: true,
            onClick: handleSearch,
          }}
          placeholder="Search..."
          value={searchKeyword}
          ref={searchInputRef}
          onChange={(e) => {
            setSearchKeyword(e.target.value);
          }}
          onKeyDown={handleSearch}
        />
      </div>
      <Grid>
        <Grid.Column width={customerDetail.id ? 12 : 16}>
          <ul className="customer-grid customer-header">
            <li className="cell">ID</li>
            <li className="cell">名前</li>
            <li className="cell">フリガナ</li>
            <li className="cell">保護者</li>
            <li className="cell">入会店舗</li>
            <li className="cell">電話番号</li>
            <li className="cell">メール</li>
            <li className="cell">申込日</li>
            <li className="cell">入会月</li>
            <li className="cell">詳細</li>
          </ul>

          {filteredCustomers.map((customer) => (
            <ul
              key={customer.id}
              className={`customer-grid ${
                customer.subscription_id ? "" : "still"
              }`}
            >
              <li className="cell">{customer.id}</li>
              <li className="cell">{`${customer.last_name} ${customer.first_name}`}</li>
              <li className="cell">{`${customer.last_name_yomi} ${customer.first_name_yomi}`}</li>
              <li className="cell">{`${customer.parent_last_name} ${customer.parent_first_name}`}</li>
              <li className="cell">
                {customer.studio}
                {customer.subscription_id ? "" : customer.plan_change_denied}
                {/* {customer.plan_change_denied} */}
              </li>
              <li className="cell">{customer.phone}</li>
              <li className="cell">{customer.mail}</li>
              <li className="cell">{customer.created_at}</li>
              <li className="cell">{customer.start_month}</li>
              <li className="cell">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDetail(customer.id);
                  }}
                  content="詳細を見る"
                  size="small"
                  color="blue"
                />
              </li>
            </ul>
          ))}
        </Grid.Column>
        {customerDetail.id && (
          <Grid.Column
            width={4}
            style={{
              position: "fixed",
              right: 0,
              top: "7rem",
              height: "calc(100% - 7rem)",
              zIndex: 100,
            }}
          >
            {customerDetail.first_name ? (
              <Card
                fluid
                style={{
                  overflowY: "auto",
                  maxHeight: "100%",
                }}
              >
                <Icon
                  name="close"
                  onClick={() => {
                    setCustomerDetail({});
                  }}
                  style={{
                    position: "absolute",
                    right: "1em",
                    top: "1em",
                    cursor: "pointer",
                    zIndex: 1000,
                  }}
                />
                {customerDetail.icon_image && (
                  <Image
                    src={`https://mypage.dance-slash.com/media/${customerDetail.icon_image}`}
                    wrapped
                    ui={false}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                )}
                <CardContent>
                  <CardHeader style={{ fontSize: "1.2em" }}>
                    {`${customerDetail.last_name} ${customerDetail.first_name}`}
                    {customerDetail.age ? ` (${customerDetail.age})` : " ( - )"}
                  </CardHeader>
                  <CardMeta
                    style={{ marginBottom: "1em" }}
                  >{`${customerDetail.last_name_yomi} ${customerDetail.first_name_yomi}`}</CardMeta>
                  {/* 基本情報 */}
                  <div
                    style={{
                      paddingBottom: "1em",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <p>
                      <strong>ID: </strong>
                      {customerDetail.id ? customerDetail.id : "-"}
                    </p>
                    <p>
                      <strong>メールアドレス: </strong>
                      {customerDetail.mail ? customerDetail.mail : "-"}
                    </p>
                    <p>
                      <strong>電話番号: </strong>
                      {customerDetail.phone ? customerDetail.phone : "-"}
                    </p>
                    <p>
                      <strong>年齢: </strong>
                      {customerDetail.age ? customerDetail.age : "-"}
                    </p>
                    <p>
                      <strong>生年月日: </strong>
                      {customerDetail.birthday ? customerDetail.birthday : "-"}
                    </p>
                  </div>
                  {/* プラン情報 */}
                  {(customerDetail.now_plan || customerDetail.new_plan) && (
                    <>
                      <CardHeader
                        style={{
                          fontSize: "1.2em",
                          marginTop: "1em",
                          marginBottom: "1em",
                        }}
                      >
                        プラン
                      </CardHeader>
                      <div
                        style={{
                          paddingBottom: "1em",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {customerDetail.now_plan && (
                          <div>
                            <p>
                              <strong>現在のプラン</strong>{" "}
                            </p>
                            <p>プラン名: {customerDetail.now_plan.name}</p>
                            {customerDetail.now_plan.start_month && (
                              <p>
                                開始月: {customerDetail.now_plan.start_month}
                              </p>
                            )}
                            {customerDetail.now_plan.lessons && (
                              <p>所属するレッスン: </p>
                            )}
                            {customerDetail.now_plan.lessons &&
                              customerDetail.now_plan.lessons.map(
                                (lesson, i) => <p key={i}>{lesson}</p>
                              )}
                          </div>
                        )}
                        {customerDetail.new_plan && (
                          <div
                            style={{
                              marginTop: customerDetail.now_plan && "1.5em",
                            }}
                          >
                            <p>
                              <strong>変更予定のプラン</strong>{" "}
                            </p>
                            <p>プラン名: {customerDetail.new_plan.name}</p>
                            {customerDetail.new_plan.start_month && (
                              <p>
                                開始月: {customerDetail.new_plan.start_month}
                              </p>
                            )}
                            {customerDetail.new_plan.lessons && (
                              <p>所属するレッスン: </p>
                            )}
                            {customerDetail.new_plan.lessons &&
                              customerDetail.new_plan.lessons.map(
                                (lesson, i) => <p key={i}>{lesson}</p>
                              )}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {/* 支払い履歴 */}
                  <CardHeader style={{ marginTop: "1em" }}>
                    支払い履歴（{customerDetail.payment_method}）
                  </CardHeader>
                  {customerDetail.invoices &&
                  customerDetail.invoices.length > 0 ? (
                    <List divided relaxed>
                      {customerDetail.invoices &&
                        customerDetail.invoices.map((invoice, index) => (
                          <ListItem key={index}>
                            <ListContent
                              as="a"
                              href={invoice.hosted_invoice_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="invoice-item"
                            >
                              {invoice.created_at} {invoice.amount}円{" "}
                              {invoice.status === "paid" && "支払い済み"}
                              {invoice.status === "open" && "未払い"}
                            </ListContent>
                          </ListItem>
                        ))}
                    </List>
                  ) : (
                    <p style={{ margin: "1em 0" }}>支払い履歴はありません</p>
                  )}
                  {/* 受講履歴 */}
                  <CardHeader style={{ marginTop: "1em" }}>受講履歴</CardHeader>
                  {customerDetail.attendances &&
                  customerDetail.attendances.length > 0 ? (
                    <List divided relaxed>
                      {customerDetail.attendances &&
                        customerDetail.attendances.map((attendance, index) => (
                          <ListItem key={index}>
                            <ListContent>
                              {attendance.date} {attendance.lesson}
                            </ListContent>
                          </ListItem>
                        ))}
                    </List>
                  ) : (
                    <p style={{ margin: "1em 0" }}>受講履歴はありません</p>
                  )}
                  {/* 受講履歴 */}

                  {customerDetail.subscription_id ? (
                    <>
                      <Button color="red" onClick={handleDelete}>
                        退会
                      </Button>
                    </>
                  ) : (
                    <>
                      <Input
                        fluid
                        placeholder="入会金"
                        value={admissionFee}
                        name="admissionFee"
                        onChange={(e) => {
                          setAdmissionFee(e.target.value);
                        }}
                      />
                      <Button primary onClick={handleAdmission}>
                        入会
                      </Button>
                      <Button onClick={handleCancel}>取消</Button>
                    </>
                  )}
                </CardContent>
              </Card>
            ) : (
              <Segment style={{ height: "500px" }}>
                <Dimmer active inverted>
                  <Loader />
                </Dimmer>
              </Segment>
            )}
          </Grid.Column>
        )}
      </Grid>
    </>
  );
}

export default CustomerListPage;
