import React, { useState, useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
import { useLoginCheck } from "../../hooks/useLoginCheck";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Chart.js のプラグインを登録
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function AccessQuestionnairePage() {
  useLoginCheck();

  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);

  const timeLabels = [
    "0～5分",
    "5～10分",
    "10～15分",
    "15～20分",
    "20～25分",
    "25～30分",
    "30分以上",
  ];

  // 表示順
  const transportationList = ["徒歩", "自転車", "車", "電車", "バス"];

  const getTimeBinIndex = (time) => {
    if (time < 5) return 0;
    if (time < 10) return 1;
    if (time < 15) return 2;
    if (time < 20) return 3;
    if (time < 25) return 4;
    if (time < 30) return 5;
    return 6; // 30分以上
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(
          `${apiUrl}/api/get_access_questionnaires/`
        );
        const result = await response.json();
        setApiData(result);
      } catch (error) {
        console.error("データ取得に失敗しました", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (!apiData) return <div>データがありません。</div>;

  const { questionnaire_data, studios } = apiData;

  // スタジオごとのオブジェクト
  const studioTransportMap = {};
  // 全スタジオ用
  const overallTransportMap = {};
  transportationList.forEach((transportation) => {
    overallTransportMap[transportation] = [];
  });

  // studios の初期化
  studios.forEach((studioName) => {
    studioTransportMap[studioName] = {};
    transportationList.forEach((transportation) => {
      studioTransportMap[studioName][transportation] = [];
    });
  });

  // 集計
  questionnaire_data.forEach((item) => {
    const { studio, transportation, time } = item;
    if (overallTransportMap[transportation]) {
      overallTransportMap[transportation].push(time);
    }
    if (
      studioTransportMap[studio] &&
      studioTransportMap[studio][transportation] !== undefined
    ) {
      studioTransportMap[studio][transportation].push(time);
    }
  });

  // グラフレンダリング
  const renderChartForTransportation = (transportMap, titlePrefix) => {
    return transportationList.map((transportation) => {
      const binCounts = Array(timeLabels.length).fill(0);
      transportMap[transportation].forEach((t) => {
        const binIndex = getTimeBinIndex(t);
        binCounts[binIndex]++;
      });

      const chartData = {
        labels: timeLabels,
        datasets: [
          {
            label: `${transportation}`,
            data: binCounts,
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
        ],
      };

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: "所要時間",
            },
          },
          y: {
            title: {
              display: true,
              text: "人数",
            },
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: `${titlePrefix} - ${transportation}`,
          },
          legend: {
            display: false,
          },
        },
      };

      return (
        <div key={transportation} className="chart-wrapper">
          <Bar data={chartData} options={options} />
        </div>
      );
    });
  };

  // 全スタジオの合計人数
  const overallSummary = transportationList.map((t) => {
    return `${t}: ${overallTransportMap[t].length}人`;
  });

  return (
    <div>
      <h1>スタジオ別 交通手段×所要時間 グラフ</h1>

      {/* ▼▼▼ ページ上部にメニューを追加 ▼▼▼ */}
      <ul className="accses-studio-menu">
        {/* 「全スタジオ」へ飛ぶリンク */}
        <a href="#studio-overall">
          <li>全スタジオ</li>
        </a>
        {/* 各スタジオへ飛ぶリンクを動的に生成 */}
        {studios.map((studioName) => (
          <a href={`#studio-${studioName}`}>
            <li key={studioName}>{studioName}</li>
          </a>
        ))}
      </ul>
      <hr />

      {/* ▼ 全スタジオセクション ▼ */}
      <h2 id="studio-overall">全スタジオ</h2>
      <p style={{ fontWeight: "bold", margin: "10px 0" }}>
        {overallSummary.join("　")}
      </p>
      <div className="chart-area">
        {renderChartForTransportation(overallTransportMap, "全スタジオ")}
      </div>

      {/* ▼ 各スタジオセクション ▼ */}
      {studios.map((studioName) => {
        const summaryArray = transportationList.map((transportation) => {
          return `${transportation}: ${studioTransportMap[studioName][transportation].length}人`;
        });
        return (
          <div key={studioName} id={`studio-${studioName}`}>
            {/* セクションにIDを付与してジャンプ先にする */}
            <h2>{studioName}</h2>
            <p style={{ fontWeight: "bold", margin: "10px 0" }}>
              {summaryArray.join("　")}
            </p>

            <div className="chart-area">
              {renderChartForTransportation(
                studioTransportMap[studioName],
                studioName
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AccessQuestionnairePage;
