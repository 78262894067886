import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";

const COLORS = ["#00C49F", "#0088FE", "#FFBB28", "#FF8042", "#8884d8"];
const darkTheme = {
  background: "#1a1a1a",
  paper: "#242424",
  text: "#ffffff",
  subText: "#b0b0b0",
  gridLines: "#333333",
  border: "#404040",
  chartBackground: "#2a2a2a",
  tooltipBackground: "rgba(36, 36, 36, 0.95)",
};

const cardStyles = {
  bgcolor: darkTheme.paper,
  border: `1px solid ${darkTheme.border}`,
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 6px 8px rgba(0, 0, 0, 0.2)",
  },
};

const chartContainerStyles = {
  width: "100%",
  height: 350,
  p: 2,
  bgcolor: darkTheme.chartBackground,
  borderRadius: 1,
  boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
};

const AnalyticsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [selectedStudio, setSelectedStudio] = useState("all");
  const [studios, setStudios] = useState([]);
  const [analyticsData, setAnalyticsData] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const params = new URLSearchParams({
        start_date: format(startDate, "yyyy-MM-dd"),
        end_date: format(endDate, "yyyy-MM-dd"),
        studio_id: selectedStudio,
      });
      const response = await fetch(
        `${apiUrl}/api/website/get_analytics/?${params}`
      );
      if (!response.ok) {
        throw new Error("データの取得に失敗しました");
      }
      const data = await response.json();
      setAnalyticsData(data);
      if (!studios.length && data.studios) {
        setStudios(data.studios);
      }
      console.log("Fetched analytics data:", data);
      if (data.postalCodeDistribution) {
        console.log("Postal code distribution:", data.postalCodeDistribution);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert(error.message);
    }
  }, [startDate, endDate, selectedStudio, studios.length]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!analyticsData) {
    return <div style={{ color: darkTheme.text }}>Loading...</div>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4, color: darkTheme.text }}>
        <Grid container spacing={3}>
          {/* フィルター */}
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                bgcolor: darkTheme.paper,
                border: `1px solid ${darkTheme.border}`,
                "& .MuiInputBase-root": {
                  color: darkTheme.text,
                  bgcolor: darkTheme.background,
                },
                "& .MuiInputLabel-root": {
                  color: darkTheme.subText,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: darkTheme.border,
                },
              }}
            >
              <DatePicker
                label="開始日"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                slotProps={{ textField: { size: "small" } }}
              />
              <DatePicker
                label="終了日"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                slotProps={{ textField: { size: "small" } }}
              />
              <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel>スタジオ</InputLabel>
                <Select
                  value={selectedStudio}
                  label="スタジオ"
                  onChange={(e) => setSelectedStudio(e.target.value)}
                >
                  <MenuItem value="all">全て</MenuItem>
                  {studios.map((studio) => (
                    <MenuItem key={studio.id} value={studio.id}>
                      {studio.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Paper>
          </Grid>

          {/* 主要指標 */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={cardStyles}>
                  <CardContent>
                    <Typography sx={{ color: darkTheme.subText }} gutterBottom>
                      総顧客数
                    </Typography>
                    <Typography variant="h4" sx={{ color: darkTheme.text }}>
                      {analyticsData.totalCustomers.toLocaleString()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={cardStyles}>
                  <CardContent>
                    <Typography sx={{ color: darkTheme.subText }} gutterBottom>
                      新規顧客数
                    </Typography>
                    <Typography variant="h4" sx={{ color: darkTheme.text }}>
                      {analyticsData.newCustomers.toLocaleString()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={cardStyles}>
                  <CardContent>
                    <Typography sx={{ color: darkTheme.subText }} gutterBottom>
                      退会顧客数
                    </Typography>
                    <Typography variant="h4" sx={{ color: darkTheme.text }}>
                      {analyticsData.removedCustomers.toLocaleString()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={cardStyles}>
                  <CardContent>
                    <Typography sx={{ color: darkTheme.subText }} gutterBottom>
                      売上
                    </Typography>
                    <Typography variant="h4" sx={{ color: darkTheme.text }}>
                      ¥{analyticsData.totalRevenue.toLocaleString()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          {/* 顧客数推移 */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ ...cardStyles, p: 2 }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: darkTheme.text }}
              >
                顧客数推移
              </Typography>
              <Box sx={chartContainerStyles}>
                <ResponsiveContainer>
                  <LineChart
                    data={analyticsData.customerTrend}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      stroke={darkTheme.gridLines}
                    />
                    <XAxis
                      dataKey="month"
                      tickFormatter={(value) =>
                        format(new Date(value), "yyyy/MM")
                      }
                      stroke={darkTheme.text}
                    />
                    <YAxis stroke={darkTheme.text} />
                    <Tooltip
                      labelFormatter={(value) =>
                        format(new Date(value), "yyyy/MM")
                      }
                      contentStyle={{
                        backgroundColor: darkTheme.tooltipBackground,
                        border: `1px solid ${darkTheme.border}`,
                        borderRadius: "4px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
                      }}
                    />
                    <Legend wrapperStyle={{ color: darkTheme.text }} />
                    <Line
                      type="monotone"
                      dataKey="count"
                      name="顧客数"
                      stroke={COLORS[0]}
                      strokeWidth={2}
                      dot={{ r: 4 }}
                      activeDot={{ r: 6 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>

          {/* レッスン出席率 */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ ...cardStyles, p: 2 }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: darkTheme.text }}
              >
                レッスン出席率
              </Typography>
              <Box sx={chartContainerStyles}>
                <ResponsiveContainer>
                  <LineChart
                    data={analyticsData.attendanceData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      stroke={darkTheme.gridLines}
                    />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(value) =>
                        format(new Date(value), "MM/dd")
                      }
                      stroke={darkTheme.text}
                    />
                    <YAxis domain={[0, 100]} stroke={darkTheme.text} />
                    <Tooltip
                      labelFormatter={(value) =>
                        format(new Date(value), "yyyy/MM/dd")
                      }
                      formatter={(value) => [`${value}%`, "出席率"]}
                      contentStyle={{
                        backgroundColor: darkTheme.tooltipBackground,
                        border: `1px solid ${darkTheme.border}`,
                        borderRadius: "4px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
                      }}
                    />
                    <Legend wrapperStyle={{ color: darkTheme.text }} />
                    <Line
                      type="monotone"
                      dataKey="attendance_rate"
                      name="出席率"
                      stroke={COLORS[1]}
                      strokeWidth={2}
                      dot={{ r: 4 }}
                      activeDot={{ r: 6 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>

          {/* プラン別顧客分布 */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ ...cardStyles, p: 2 }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: darkTheme.text }}
              >
                プラン別顧客分布
              </Typography>
              <Box sx={chartContainerStyles}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={analyticsData.planDistribution}
                      dataKey="count"
                      nameKey="plan"
                      cx="50%"
                      cy="50%"
                      outerRadius={isMobile ? 80 : 100}
                      fill={COLORS[0]}
                      label
                    >
                      {analyticsData.planDistribution.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip
                      contentStyle={{
                        backgroundColor: darkTheme.tooltipBackground,
                        border: `1px solid ${darkTheme.border}`,
                        borderRadius: "4px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
                      }}
                    />
                    <Legend wrapperStyle={{ color: darkTheme.text }} />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>

          {/* 年齢層分布 */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ ...cardStyles, p: 2 }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: darkTheme.text }}
              >
                年齢層分布
              </Typography>
              <Box sx={chartContainerStyles}>
                <ResponsiveContainer>
                  <BarChart
                    data={Object.entries(analyticsData.ageDistribution).map(
                      ([range, count]) => ({
                        range,
                        count,
                      })
                    )}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      stroke={darkTheme.gridLines}
                    />
                    <XAxis dataKey="range" stroke={darkTheme.text} />
                    <YAxis stroke={darkTheme.text} />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: darkTheme.tooltipBackground,
                        border: `1px solid ${darkTheme.border}`,
                        borderRadius: "4px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
                      }}
                    />
                    <Legend wrapperStyle={{ color: darkTheme.text }} />
                    <Bar
                      dataKey="count"
                      name="人数"
                      fill={COLORS[2]}
                      radius={[4, 4, 0, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>

          {/* 休校数 */}
          <Grid item xs={12}>
            <Card sx={cardStyles}>
              <CardContent>
                <Typography sx={{ color: darkTheme.subText }} gutterBottom>
                  期間中の休校数
                </Typography>
                <Typography variant="h4" sx={{ color: darkTheme.text }}>
                  {analyticsData.cancellationCount.toLocaleString()}回
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
};

export default AnalyticsPage;
