import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link,
  useLocation,
} from "react-router-dom";
import "./styles/common.scss";
import { Menu, Icon, Header } from "semantic-ui-react";
import { useAuth } from "./contexts/AuthContext";
import LoadingSpinner from "./components/LoadingSpinner";
import TopPage from "./pages/management/TopPage";
import LoginPage from "./pages/auth/LoginPage";
import CustomerListPage from "./pages/management/CustomerListPage";
import FreeCustomerListPage from "./pages/management/FreeCustomerListPage";
import RemoveCustomerListPage from "./pages/management/RemoveCustomerListPage";
import SendMailPage from "./pages/management/SendMailPage";
import AccessQuestionnairePage from "./pages/management/AccessQuestionnairePage";
import Recital2025CustomerListPage from "./pages/management/recital/2025/CustomerListPage";
import Recital2025SeatPage from "./pages/management/recital/2025/SeatPage";
import Recital2025StudentSeatPage from "./pages/management/recital/2025/StudentSeatPage";
import Recital2025SpecialFeePage from "./pages/management/recital/2025/SpecialFeePage";
import Recital2025MusicEditPage from "./pages/management/recital/2025/MusicEditPage";
import Recital2025QuestionnairePage from "./pages/management/recital/2025/QuestionnairePage";
import Recital2025DiskOrderListPage from "./pages/management/recital/2025/DiskOrderListPage";
import OrderListPage from "./pages/management/OrderListPage";
import OrderPrintListPage from "./pages/management/OrderPrintListPage";
import RestockPage from "./pages/management/RestockPage";
import OpenNoticePage from "./pages/management/OpenNoticePage";
import SalaryPage from "./pages/management/SalaryPage";
import WebsiteManagementPage from "./pages/website/WebsiteManagementPage";
import StudioListPage from "./pages/website/studio/StudioListPage";
import StudioEditPage from "./pages/website/studio/StudioEditPage";
import InstructorListPage from "./pages/website/instructor/InstructorListPage";
import InstructorEditPage from "./pages/website/instructor/InstructorEditPage";
import LessonListPage from "./pages/website/lesson/LessonListPage";
import LessonEditPage from "./pages/website/lesson/LessonEditPage";
import NewsListPage from "./pages/website/news/NewsListPage";
import NewsEditPage from "./pages/website/news/NewsEditPage";
import VisualPage from "./pages/website/visual/VisualPage";
import QaPage from "./pages/website/qa/QaPage";
import FaqListPage from "./pages/website/faq/FaqListPage";
import FaqEditPage from "./pages/website/faq/FaqEditPage";
import AnalyticsPage from "./pages/website/analytics/AnalyticsPage";
import CampaignListPage from "./pages/website/campaign/CampaignListPage";
import CampaignEditPage from "./pages/website/campaign/CampaignEditPage";
import ReviewPage from "./pages/website/review/ReviewPage";
import BannerListPage from "./pages/mypage/banner/BannerListPage";
import MenuPage from "./pages/mypage/menu/MenuPage";
import PushNotificationListPage from "./pages/mypage/push-notification/PushNotificationListPage";
import PushNotificationEditPage from "./pages/mypage/push-notification/PushNotificationEditPage";
import useBodyClass from "./hooks/useBodyClass";
import ChatPage from "./pages/mypage/chat/ChatPage";
import BroadcastMessageListPage from "./pages/mypage/broadcast-message/BroadcastMessageListPage";
import BroadcastMessageEditPage from "./pages/mypage/broadcast-message/BroadcastMessageEditPage";

function App() {
  const { isLoggedIn, logout } = useAuth();

  const handleLogout = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/logout/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      console.log(data);
      await logout();
      window.location.href = "/login";
    } catch (error) {
      alert("ログアウトに失敗しました。");
    }
  };

  const SidebarLayout = ({ children, menuType }) => {
    const location = useLocation();

    // 管理画面
    const managementMenu = (
      <>
        <Menu.Item
          as={Link}
          to="/"
          className={location.pathname === "/" ? "on" : ""}
        >
          <Icon name="dashboard" />
          <span>ダッシュボード</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/management/customer-list"
          className={
            location.pathname === "/management/customer-list" ? "on" : ""
          }
        >
          <Icon name="users" />
          <span>生徒一覧</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/management/free-customer-list"
          className={
            location.pathname === "/management/free-customer-list" ? "on" : ""
          }
        >
          <Icon name="clipboard list" />
          <span>体験申込者一覧</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/management/remove-customer-list"
          className={
            location.pathname === "/management/remove-customer-list" ? "on" : ""
          }
        >
          <Icon name="user delete" />
          <span>退会者一覧</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/management/send-mail"
          className={location.pathname === "/management/send-mail" ? "on" : ""}
        >
          <Icon name="mail" />
          <span>メール送信</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/management/access-questionnaire"
          className={
            location.pathname === "/management/access-questionnaire" ? "on" : ""
          }
        >
          <Icon name="train" />
          <span>アクセスアンケート</span>
        </Menu.Item>
        <Recital2025 />
        <OnlineStore />
        <Menu.Item
          as={Link}
          to="/management/salary"
          className={location.pathname === "/management/salary" ? "on" : ""}
        >
          <Icon name="yen sign" />
          <span>給与一覧</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/management/open-notice"
          className={
            location.pathname === "/management/open-notice" ? "on" : ""
          }
        >
          <Icon name="calendar" />
          <span>予約開始リクエスト</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/studio-list"
          className={location.pathname === "/website/studio-list" ? "on" : ""}
        >
          <Icon name="desktop" />
          <span>WEBサイト管理</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/mypage/banner-list"
          className={location.pathname === "/mypage/banner-list" ? "on" : ""}
        >
          <Icon name="mobile alternate" />
          <span>マイページアプリ管理</span>
        </Menu.Item>
        <Menu.Item as="a" onClick={handleLogout}>
          <Icon name="log out" />
          <span>ログアウト</span>
        </Menu.Item>
      </>
    );

    // WEBサイト管理
    const websiteMenu = (
      <>
        <Menu.Item
          as={Link}
          to="/website/studio-list"
          className={location.pathname === "/website/studio-list" ? "on" : ""}
        >
          <Icon name="building" />
          <span> スタジオ</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/instructor-list"
          className={
            location.pathname === "/website/instructor-list" ? "on" : ""
          }
        >
          <Icon name="child" />
          <span> インストラクター</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/lesson-list"
          className={location.pathname === "/website/lesson-list" ? "on" : ""}
        >
          <Icon name="calendar check" />
          <span> レッスン</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/visual"
          className={location.pathname === "/website/visual" ? "on" : ""}
        >
          <Icon name="camera" />
          <span> 写真 / 動画</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/qa"
          className={location.pathname === "/website/qa" ? "on" : ""}
        >
          <Icon name="question" />
          <span>Q & A</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/faq-list"
          className={location.pathname === "/website/faq-list" ? "on" : ""}
        >
          <Icon name="question" />
          <span>よくある質問</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/news-list"
          className={location.pathname === "/website/news-list" ? "on" : ""}
        >
          <Icon name="edit" />
          <span>投稿</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/review"
          className={location.pathname === "/website/review" ? "on" : ""}
        >
          <Icon name="comment" />
          <span> 口コミ</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/campaign-list"
          className={location.pathname === "/website/campaign-list" ? "on" : ""}
        >
          <Icon name="bullhorn" />
          <span>キャンペーン</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/website/analytics"
          className={location.pathname === "/website/analytics" ? "on" : ""}
        >
          <Icon name="chart bar" />
          <span> 解析</span>
        </Menu.Item>
        <Menu.Item as={Link} to="/">
          <Icon name="arrow left" />
          <span> 管理画面に戻る</span>
        </Menu.Item>
      </>
    );

    // マイページアプリ管理
    const mypageMenu = (
      <>
        <Menu.Item
          as={Link}
          to="/mypage/banner-list"
          className={location.pathname === "/mypage/banner-list" ? "on" : ""}
        >
          <Icon name="images" />
          <span>バナー</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/mypage/chat"
          className={location.pathname === "/mypage/chat" ? "on" : ""}
        >
          <Icon name="mail" />
          <span>問い合わせ</span>
        </Menu.Item>{" "}
        <Menu.Item
          as={Link}
          to="/mypage/broadcast-message-list"
          className={
            location.pathname === "/mypage/broadcast-message-list" ? "on" : ""
          }
        >
          <Icon name="announcement" />
          <span>一斉送信</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/mypage/menu"
          className={location.pathname === "/mypage/menu" ? "on" : ""}
        >
          <Icon name="list" />
          <span>メニュー管理</span>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/mypage/push-notification-list"
          className={
            location.pathname === "/mypage/push-notification-list" ? "on" : ""
          }
        >
          <Icon name="bell" />
          <span>通知管理</span>
        </Menu.Item>
        <Menu.Item as={Link} to="/">
          <Icon name="arrow left" />
          <span> 管理画面に戻る</span>
        </Menu.Item>
      </>
    );

    return location.pathname === "/management/order-print-list" ? (
      children
    ) : (
      <>
        <Header className="top" textAlign="left">
          SLASH ADMIN
        </Header>
        <LoadingSpinner />
        <main>
          <div className="sidebar">
            {menuType === "management" && managementMenu}
            {menuType === "website" && websiteMenu}
            {menuType === "mypage" && mypageMenu}
          </div>
          <div className="inner">{children}</div>
        </main>
      </>
    );
  };

  const RenderPage = () => {
    useBodyClass();

    if (!isLoggedIn) {
      return <Navigate to="/login" replace />;
    }

    return (
      <Routes>
        <Route
          path="/"
          element={
            <SidebarLayout menuType="management">
              <TopPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/customer-list"
          element={
            <SidebarLayout menuType="management">
              <CustomerListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/free-customer-list"
          element={
            <SidebarLayout menuType="management">
              <FreeCustomerListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/remove-customer-list"
          element={
            <SidebarLayout menuType="management">
              <RemoveCustomerListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/send-mail"
          element={
            <SidebarLayout menuType="management">
              <SendMailPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/access-questionnaire"
          element={
            <SidebarLayout menuType="management">
              <AccessQuestionnairePage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/recital/2025/customer-list"
          element={
            <SidebarLayout menuType="management">
              <Recital2025CustomerListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/recital/2025/seat"
          element={
            <SidebarLayout menuType="management">
              <Recital2025SeatPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/recital/2025/student-seat"
          element={
            <SidebarLayout menuType="management">
              <Recital2025StudentSeatPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/recital/2025/special-fee"
          element={
            <SidebarLayout menuType="management">
              <Recital2025SpecialFeePage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/recital/2025/music-edit"
          element={
            <SidebarLayout menuType="management">
              <Recital2025MusicEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/recital/2025/questionnaire"
          element={
            <SidebarLayout menuType="management">
              <Recital2025QuestionnairePage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/recital/2025/disk-order-list"
          element={
            <SidebarLayout menuType="management">
              <Recital2025DiskOrderListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/order-list"
          element={
            <SidebarLayout menuType="management">
              <OrderListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/order-print-list"
          element={
            <SidebarLayout menuType="management">
              <OrderPrintListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/restock"
          element={
            <SidebarLayout menuType="management">
              <RestockPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/open-notice"
          element={
            <SidebarLayout menuType="management">
              <OpenNoticePage />
            </SidebarLayout>
          }
        />
        <Route
          path="/management/salary"
          element={
            <SidebarLayout menuType="management">
              <SalaryPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/website-management"
          element={
            <SidebarLayout menuType="website">
              <WebsiteManagementPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/studio-list"
          element={
            <SidebarLayout menuType="website">
              <StudioListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/studio-edit/:studioId"
          element={
            <SidebarLayout menuType="website">
              <StudioEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/instructor-list"
          element={
            <SidebarLayout menuType="website">
              <InstructorListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/instructor-edit/:instructorId"
          element={
            <SidebarLayout menuType="website">
              <InstructorEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/lesson-list"
          element={
            <SidebarLayout menuType="website">
              <LessonListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/lesson-edit/:studioId"
          element={
            <SidebarLayout menuType="website">
              <LessonEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/visual"
          element={
            <SidebarLayout menuType="website">
              <VisualPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/qa"
          element={
            <SidebarLayout menuType="website">
              <QaPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/faq-list"
          element={
            <SidebarLayout menuType="website">
              <FaqListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/faq-edit/:id?"
          element={
            <SidebarLayout menuType="website">
              <FaqEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/news-list"
          element={
            <SidebarLayout menuType="website">
              <NewsListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/news-edit/:id?"
          element={
            <SidebarLayout menuType="website">
              <NewsEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/review"
          element={
            <SidebarLayout menuType="website">
              <ReviewPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/campaign-list"
          element={
            <SidebarLayout menuType="website">
              <CampaignListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/campaign-edit/:id?"
          element={
            <SidebarLayout menuType="website">
              <CampaignEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/website/analytics"
          element={
            <SidebarLayout menuType="website">
              <AnalyticsPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/mypage/banner-list"
          element={
            <SidebarLayout menuType="mypage">
              <BannerListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/mypage/chat"
          element={
            <SidebarLayout menuType="mypage">
              <ChatPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/mypage/menu"
          element={
            <SidebarLayout menuType="mypage">
              <MenuPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/mypage/broadcast-message-list"
          element={
            <SidebarLayout menuType="mypage">
              <BroadcastMessageListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/mypage/broadcast-message-edit/:id?"
          element={
            <SidebarLayout menuType="mypage">
              <BroadcastMessageEditPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/mypage/push-notification-list"
          element={
            <SidebarLayout menuType="mypage">
              <PushNotificationListPage />
            </SidebarLayout>
          }
        />
        <Route
          path="/mypage/push-notification-edit/:id?"
          element={
            <SidebarLayout menuType="mypage">
              <PushNotificationEditPage />
            </SidebarLayout>
          }
        />
      </Routes>
    );
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<RenderPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

// 発表会2025
const Recital2025 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const pages = [
    {
      text: "参加者一覧",
      path: "/management/recital/2025/customer-list",
      target: "",
    },
    { text: "座席管理", path: "/management/recital/2025/seat", target: "" },
    {
      text: "生徒座席管理",
      path: "/management/recital/2025/student-seat",
      target: "",
    },
    {
      text: "タイムテーブル",
      path: "https://staff.dance-slash.com/guluu/recital/sort_perform?year=2025",
      target: "_blank",
    },
    {
      text: "点呼表",
      path: "https://staff.dance-slash.com/guluu/recital/rollcall?year=2025",
      target: "_blank",
    },
    {
      text: "パンフレット写真一覧",
      path: "https://mypage.dance-slash.com/recital2025/show_image_list/",
      target: "_blank",
    },
    {
      text: "イントラ給料",
      path: "/management/recital/2025/special-fee",
      target: "",
    },
    {
      text: "イントラ音編",
      path: "/management/recital/2025/music-edit",
      target: "",
    },
    {
      text: "アンケートページ",
      path: "https://mypage.dance-slash.com/recital2025/questionnaire/",
      target: "_blank",
    },
    {
      text: "アンケート管理",
      path: "/management/recital/2025/questionnaire",
      target: "",
    },
    {
      text: "DVD購入ページ",
      path: "https://mypage.dance-slash.com/recital2025/disk/order/",
      target: "_blank",
    },
    {
      text: "DVD購入者一覧",
      path: "/management/recital/2025/disk-order-list",
      target: "",
    },
    {
      text: "貼紙印刷用ページ",
      path: "https://staff.dance-slash.com/guluu/recital2025/print_seat",
      target: "_blank",
    },
  ];

  return (
    <>
      <Menu.Item
        className={`accordion-item ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon name="music" />
        <span>発表会2025</span>
      </Menu.Item>
      <ul
        className="children"
        style={{
          height: isOpen ? `${pages.length * 50}px` : "0px",
          overflow: "hidden",
          transition: "height 0.3s ease",
        }}
      >
        {pages.map((page, index) => (
          <Link
            to={page.path}
            className={location.pathname === page.path ? "on" : ""}
            target={page.target}
            key={index}
          >
            <li>{page.text}</li>
          </Link>
        ))}
      </ul>
    </>
  );
};

// オンラインストア
const OnlineStore = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const pages = [
    { text: "注文一覧", path: "/management/order-list" },
    { text: "注文一覧（印刷用）", path: "/management/order-print-list" },
    { text: "再入荷リクエスト", path: "/management/restock" },
  ];

  return (
    <>
      <Menu.Item
        className={`accordion-item ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon name="shopping cart" />
        <span>オンラインストア</span>
      </Menu.Item>
      <ul
        className="children"
        style={{
          height: isOpen ? `${pages.length * 50}px` : "0px",
          overflow: "hidden",
          transition: "height 0.3s ease",
        }}
      >
        {pages.map((page, index) => (
          <Link
            to={page.path}
            className={location.pathname === page.path ? "on" : ""}
            key={index}
          >
            <li>{page.text}</li>
          </Link>
        ))}
      </ul>
    </>
  );
};
