"use client";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Header,
  Button,
  Table,
  Icon,
  Modal,
  Segment,
  Dropdown,
  Pagination,
  Input,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

function PushNotificationListPage() {
  const [notifications, setNotifications] = useState([]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState("all");

  const itemsPerPage = 10;

  // ステータスフィルターオプション
  const statusOptions = [
    { key: "all", text: "すべて", value: "all" },
    { key: "sent", text: "送信済み", value: "sent" },
    { key: "scheduled", text: "予約済み", value: "scheduled" },
    { key: "draft", text: "下書き", value: "draft" },
  ];

  // 検索とフィルタリングを適用した通知リスト
  const filteredNotifications = notifications.filter((notification) => {
    const matchesSearch =
      notification.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      notification.content.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus =
      statusFilter === "all" || notification.status === statusFilter;

    return matchesSearch && matchesStatus;
  });

  // ページネーション用のデータ
  const totalPages = Math.ceil(filteredNotifications.length / itemsPerPage);
  const paginatedNotifications = filteredNotifications.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // 通知削除の確認モーダルを開く
  const openDeleteModal = (notification) => {
    setSelectedNotification(notification);
    setDeleteModalOpen(true);
  };

  // 通知を削除する
  const handleDelete = async () => {
    if (selectedNotification) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(
          `${apiUrl}/api/mypage/delete_push_notification/${selectedNotification.id}/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("通知の削除に失敗しました");
        }

        // 画面上の通知リストから削除
        setNotifications(
          notifications.filter((n) => n.id !== selectedNotification.id)
        );
        setDeleteModalOpen(false);
        setSelectedNotification(null);
      } catch (error) {
        console.error("通知の削除中にエラーが発生しました:", error);
        alert("通知の削除に失敗しました。");
      }
    }
  };

  // ステータスに応じたラベルを返す
  const getStatusLabel = (status) => {
    switch (status) {
      case "sent":
        return <span className="status-label sent">送信済み</span>;
      case "scheduled":
        return <span className="status-label scheduled">予約済み</span>;
      case "pending":
        return <span className="status-label pending">未配信</span>;
      default:
        return null;
    }
  };

  // 日時を適切にフォーマットする関数
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "-";

    // UTCの日時文字列をJST（日本時間）で表示
    const date = new Date(dateTimeString);
    return new Intl.DateTimeFormat("ja-JP", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Asia/Tokyo",
    }).format(date);
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(
          `${apiUrl}/api/mypage/get_push_notifications/`
        );
        if (!response.ok) {
          throw new Error("ネットワークレスポンスが異常です。");
        }
        const data = await response.json();
        setNotifications(data);
      } catch (error) {
        console.error(
          "プッシュ通知データの取得中にエラーが発生しました:",
          error
        );
      }
    };

    fetchNotifications();
  }, []);

  return (
    <div className="push-notification-list">
      <div className="page-header">
        <Header as="h1" className="page-title">
          プッシュ通知管理
        </Header>
        <Link to="/mypage/push-notification-edit">
          <Button primary icon labelPosition="left" className="create-button">
            <Icon name="plus" />
            新規通知作成
          </Button>
        </Link>
      </div>

      <Segment className="filter-section">
        <div className="filter-controls">
          <Input
            icon="search"
            placeholder="タイトル・本文で検索..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Dropdown
            selection
            options={statusOptions}
            value={statusFilter}
            onChange={(_, data) => setStatusFilter(data.value)}
            placeholder="ステータスで絞り込み"
            className="status-filter"
          />
        </div>
      </Segment>

      <Table celled className="notification-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>タイトル</Table.HeaderCell>
            <Table.HeaderCell>本文</Table.HeaderCell>
            <Table.HeaderCell>ステータス</Table.HeaderCell>
            <Table.HeaderCell>配信タイプ</Table.HeaderCell>
            <Table.HeaderCell>配信日時</Table.HeaderCell>
            <Table.HeaderCell>送信対象</Table.HeaderCell>
            <Table.HeaderCell>操作</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {paginatedNotifications.length > 0 ? (
            paginatedNotifications.map((notification) => (
              <Table.Row key={notification.id}>
                <Table.Cell>{notification.title}</Table.Cell>
                <Table.Cell className="notification-content">
                  {notification.content}
                </Table.Cell>
                <Table.Cell>{getStatusLabel(notification.status)}</Table.Cell>
                <Table.Cell>
                  {notification.delivery_type === "immediate" ? "即時" : "予約"}
                </Table.Cell>
                <Table.Cell>
                  {notification.status === "sent" &&
                  notification.delivery_type === "immediate"
                    ? formatDateTime(notification.created_at)
                    : formatDateTime(notification.scheduled_at) || "-"}
                </Table.Cell>
                <Table.Cell>
                  {notification.target_type === "all"
                    ? "全ユーザー"
                    : "特定店舗"}
                </Table.Cell>{" "}
                <Table.Cell className="action-buttons">
                  <Link
                    to={`/mypage/push-notification-edit/${notification.id}`}
                  >
                    <Button icon size="small" className="edit-button">
                      <Icon name="edit" />
                    </Button>
                  </Link>
                  <Button
                    icon
                    size="small"
                    className="delete-button"
                    onClick={() => openDeleteModal(notification)}
                  >
                    <Icon name="trash" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan="7" textAlign="center">
                通知がありません
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      {totalPages > 1 && (
        <div className="pagination-container">
          <Pagination
            activePage={currentPage}
            totalPages={totalPages}
            onPageChange={(_, data) => setCurrentPage(data.activePage)}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
          />
        </div>
      )}

      {/* 削除確認モーダル */}
      <Modal
        size="tiny"
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        className="delete-modal"
      >
        <Modal.Header>通知の削除</Modal.Header>
        <Modal.Content>
          <p>
            「{selectedNotification?.title}」を削除してもよろしいですか？
            この操作は元に戻せません。
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setDeleteModalOpen(false)}>
            キャンセル
          </Button>
          <Button positive onClick={handleDelete}>
            削除する
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default PushNotificationListPage;
