"use client";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Header,
  Button,
  Table,
  Icon,
  Modal,
  Segment,
  Dropdown,
  Pagination,
  Input,
  Loader,
  Message,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

function BroadcastMessageListPage() {
  const [messages, setMessages] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState("all");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortColumn, setSortColumn] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const itemsPerPage = 10;

  // ステータスフィルターオプション
  const statusOptions = [
    { key: "all", text: "すべて", value: "all" },
    { key: "sent", text: "送信済み", value: "sent" },
    { key: "scheduled", text: "予約済み", value: "scheduled" },
    { key: "draft", text: "下書き", value: "draft" },
  ];

  // ステータス表示名から値への変換
  const getStatusValue = (statusLabel) => {
    switch (statusLabel) {
      case "送信済み":
        return "sent";
      case "予約済み":
        return "scheduled";
      case "下書き":
        return "draft";
      case "未配信":
        return "pending";
      default:
        return "pending";
    }
  };

  // データ取得
  const fetchMessages = async () => {
    try {
      setLoading(true);
      setError(null);
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/mypage/get_broadcast_messages/?sort_column=${sortColumn}&sort_type=${sortDirection}`
      );

      if (!response.ok) {
        throw new Error("一斉送信データの取得に失敗しました");
      }

      const result = await response.json();

      if (result.status === "success") {
        // APIデータを表示用に変換
        const formattedMessages = result.data.map((message) => ({
          id: message.id,
          text: message.text,
          delivery_type:
            message.delivery_type === "immediate" ? "即時" : "予約",
          scheduled_at: message.scheduled_at
            ? formatApiDateTime(message.scheduled_at)
            : null,
          status: getStatusFromApiData(message),
          sent_at: message.sent_at ? formatApiDateTime(message.sent_at) : null,
          is_draft: message.is_draft,
          is_sent: message.is_sent,
        }));

        setMessages(formattedMessages);
      } else {
        throw new Error(result.message || "一斉送信データの取得に失敗しました");
      }
    } catch (error) {
      console.error("一斉送信データの取得中にエラーが発生しました:", error);
      setError("一斉送信データの取得に失敗しました。");
    } finally {
      setLoading(false);
    }
  };

  // APIデータからステータスを判定
  const getStatusFromApiData = (message) => {
    if (message.is_sent) {
      return "送信済み";
    } else if (message.delivery_type === "scheduled" && !message.is_sent) {
      return "予約済み";
    } else if (message.is_draft) {
      return "下書き";
    } else {
      return "未配信";
    }
  };

  // APIの日時文字列をフォーマット
  const formatApiDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}/${String(date.getDate()).padStart(2, "0")} ${String(
      date.getHours()
    ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
  };

  // ソート処理
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [sortColumn, sortDirection]);

  // 検索とフィルタリングを適用したメッセージリスト
  const filteredMessages = messages.filter((message) => {
    const matchesSearch = message.text
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesStatus =
      statusFilter === "all" || getStatusValue(message.status) === statusFilter;
    return matchesSearch && matchesStatus;
  });

  // ページネーション用のデータ
  const totalPages = Math.ceil(filteredMessages.length / itemsPerPage);
  const paginatedMessages = filteredMessages.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const openDeleteModal = (message) => {
    setSelectedMessage(message);
    setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    if (!selectedMessage) return;

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/mypage/delete_broadcast_message/${selectedMessage.id}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("一斉送信の削除に失敗しました");
      }

      // 削除成功後、リストを更新
      setMessages(messages.filter((msg) => msg.id !== selectedMessage.id));
      setDeleteModalOpen(false);
      setSelectedMessage(null);
    } catch (error) {
      console.error("一斉送信の削除中にエラーが発生しました:", error);
      alert("一斉送信の削除に失敗しました。");
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "送信済み":
        return <span className="status-badge sent">送信済み</span>;
      case "予約済み":
        return <span className="status-badge scheduled">予約済み</span>;
      case "下書き":
        return <span className="status-badge draft">下書き</span>;
      case "未配信":
        return <span className="status-badge pending">未配信</span>;
      default:
        return <span className="status-badge pending">未配信</span>;
    }
  };

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "-";
    return dateTimeString;
  };

  return (
    <div className="broadcast-message-list">
      <div className="page-header">
        <Header as="h1" className="page-title">
          一斉送信一覧
        </Header>
        <Link to="/mypage/broadcast-message-edit">
          <Button primary icon labelPosition="left" className="create-button">
            <Icon name="plus" />
            新規通知作成
          </Button>
        </Link>
      </div>

      {loading ? (
        <Loader active inline="centered" content="データを読み込み中..." />
      ) : error ? (
        <Message negative>
          <Message.Header>エラー</Message.Header>
          <p>{error}</p>
          <Button primary onClick={fetchMessages}>
            再読み込み
          </Button>
        </Message>
      ) : (
        <>
          <Segment className="filter-section">
            <div className="filter-controls">
              <Input
                icon="search"
                placeholder="タイトル・本文で検索..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Dropdown
                selection
                options={statusOptions}
                value={statusFilter}
                onChange={(_, data) => setStatusFilter(data.value)}
                placeholder="ステータスで絞り込み"
                className="status-filter"
              />
            </div>
          </Segment>

          {filteredMessages.length === 0 ? (
            <Message info>
              <Message.Header>データがありません</Message.Header>
              <p>条件に一致する一斉送信がありません。</p>
            </Message>
          ) : (
            <>
              <Table celled inverted selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      onClick={() => handleSort("text")}
                      className="sortable"
                    >
                      メッセージ
                      {sortColumn === "text" && (
                        <Icon
                          name={
                            sortDirection === "asc"
                              ? "triangle up"
                              : "triangle down"
                          }
                        />
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      onClick={() => handleSort("delivery_type")}
                      className="sortable"
                    >
                      配信タイプ
                      {sortColumn === "delivery_type" && (
                        <Icon
                          name={
                            sortDirection === "asc"
                              ? "triangle up"
                              : "triangle down"
                          }
                        />
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      onClick={() => handleSort("scheduled_at")}
                      className="sortable"
                    >
                      予約日時
                      {sortColumn === "scheduled_at" && (
                        <Icon
                          name={
                            sortDirection === "asc"
                              ? "triangle up"
                              : "triangle down"
                          }
                        />
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      onClick={() => handleSort("is_sent")}
                      className="sortable"
                    >
                      ステータス
                      {sortColumn === "is_sent" && (
                        <Icon
                          name={
                            sortDirection === "asc"
                              ? "triangle up"
                              : "triangle down"
                          }
                        />
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      onClick={() => handleSort("sent_at")}
                      className="sortable"
                    >
                      送信日時
                      {sortColumn === "sent_at" && (
                        <Icon
                          name={
                            sortDirection === "asc"
                              ? "triangle up"
                              : "triangle down"
                          }
                        />
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell>操作</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {paginatedMessages.map((message) => (
                    <Table.Row key={message.id}>
                      <Table.Cell>{message.text}</Table.Cell>
                      <Table.Cell>{message.delivery_type}</Table.Cell>
                      <Table.Cell>
                        {formatDateTime(message.scheduled_at)}
                      </Table.Cell>
                      <Table.Cell>{getStatusLabel(message.status)}</Table.Cell>
                      <Table.Cell>{formatDateTime(message.sent_at)}</Table.Cell>
                      <Table.Cell>
                        <div className="action-buttons">
                          <Button
                            as={Link}
                            to={`/mypage/broadcast-message-edit/${message.id}`}
                            icon="edit"
                            size="tiny"
                            disabled={message.status === "送信済み"}
                          />
                          <Button
                            icon="trash"
                            size="tiny"
                            negative
                            onClick={() => openDeleteModal(message)}
                            disabled={message.status === "送信済み"}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>

              {totalPages > 1 && (
                <div className="pagination-container">
                  <Pagination
                    activePage={currentPage}
                    onPageChange={(e, { activePage }) =>
                      setCurrentPage(activePage)
                    }
                    totalPages={totalPages}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}

      {/* 削除確認モーダル */}
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        size="tiny"
      >
        <Modal.Header>一斉送信の削除</Modal.Header>
        <Modal.Content>
          <p>この一斉送信を削除してもよろしいですか？</p>
          <p className="delete-message-text">{selectedMessage?.text}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setDeleteModalOpen(false)}>
            キャンセル
          </Button>
          <Button negative onClick={handleDelete}>
            削除
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default BroadcastMessageListPage;
