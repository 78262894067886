import React, { useState, useEffect, useRef } from "react";
import "../../../../styles/scss/recital2025Questionnaire.scss";
import { useLoginCheck } from "../../../../hooks/useLoginCheck";
import { useLoading } from "../../../../contexts/LoadingContext";

function QuestionnairePage() {
  useLoginCheck();
  const { setIsLoading } = useLoading();

  // 元データ用
  const originalListRef = useRef([]);
  // 現在表示中
  const [questionnaireList, setQuestionnaireList] = useState([]);
  // インストラクター一覧
  const [instructors, setInstructors] = useState([]);
  // ソート状態
  const [sortConfig, setSortConfig] = useState({ key: null, order: "none" });

  // アンケート結果取得
  const fetchQuestionnaireData = async () => {
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/get_recital2025_questionnaire/`
      );
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      console.log("GET data:", data);

      originalListRef.current = data.recital_questionnaires;
      setQuestionnaireList(data.recital_questionnaires);
      setInstructors(data.instructors);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchQuestionnaireData();
  }, []);

  // 検索用
  const instructorList = [
    { id: 14, word: ["AYAKA", "あやか", "アヤカ"] },
    { id: 29, word: ["AYAKA_SHIGA", "あやか", "アヤカ"] },
    { id: 19, word: ["CHIAYA", "ちあや", "チアヤ"] },
    { id: 21, word: ["FANA", "ふぁな", "ファナ"] },
    { id: 30, word: ["HIKARI", "ひかり", "ヒカリ"] },
    { id: 33, word: ["HINA", "ひな", "ヒナ"] },
    { id: 36, word: ["JUNI", "じゅに", "ジュニ"] },
    { id: 35, word: ["KANO", "かの", "カノ"] },
    { id: 28, word: ["MERU", "める", "メル"] },
    { id: 37, word: ["MIKI", "みき", "ミキ"] },
    { id: 27, word: ["MIU", "みう", "ミウ"] },
    { id: 6, word: ["MIYU", "みゆ", "ミユ"] },
    { id: 31, word: ["MIZUKI", "みずき", "ミズキ", "mizuki"] },
    { id: 11, word: ["MOEKA", "もえか", "モエカ"] },
    { id: 34, word: ["NANA", "なな", "ナナ"] },
    { id: 38, word: ["NANOHA", "なのは", "ナノハ"] },
    { id: 13, word: ["NARUMI", "なるみ", "ナルミ", "なる"] },
    { id: 23, word: ["NODOKA", "のどか", "ノドカ"] },
    { id: 3, word: ["REINA", "れいな", "レイナ"] },
    { id: 8, word: ["RINA", "りな", "リナ"] },
    { id: 20, word: ["RISA", "りさ", "リサ"] },
    { id: 10, word: ["SAKI", "さき", "サキ"] },
    { id: 7, word: ["YUI", "ゆい", "ユイ", "Yui"] },
    { id: 5, word: ["YUKA", "ゆか", "ユカ"] },
    {
      id: 16,

      word: [
        "YUKA_BABY",
        "ゆか",
        "ユカ",
        "ゆかBaby",
        "YUKA baby",
        "ユカベイビー",
      ],
    },
    { id: 25, word: ["YUKINO", "ゆきの", "ユキノ"] },
    { id: 12, word: ["YUME", "ゆめ", "ユメ", "yume"] },
    { id: 39, word: ["YUNA", "ゆうな", "ユウナ"] },
  ];

  // イントラ毎に絞り込み
  const handleInstructorClick = async (instructorId) => {
    setIsLoading(true);
    const foundInstructor = instructorList.find(
      (instructor) => instructor.id === instructorId
    );
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/filter_recital2025_questionnaire/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            instructor_id: foundInstructor.id,
            word: foundInstructor.word,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      console.log(data);
      setQuestionnaireList(data.recital_questionnaires);
      // fetchOpenNoticeData();
    } catch (error) {
      console.error("送信中にエラーが発生しました:", error);
    }
    setIsLoading(false);
  };

  // ソート
  const sortArray = (array, key, order) => {
    const sorted = [...array];
    sorted.sort((a, b) => {
      // nullを末尾にしたいので、ascならnull→Infinity、descならnull→-Infinity
      const aValue =
        a[key] === null ? (order === "asc" ? Infinity : -Infinity) : a[key];
      const bValue =
        b[key] === null ? (order === "asc" ? Infinity : -Infinity) : b[key];
      return order === "asc" ? aValue - bValue : bValue - aValue;
    });
    return sorted;
  };

  // 評価クリック時
  const handleSortClick = (columnKey) => {
    // すでにこの列でソートしている場合は状態を1つ進める
    if (sortConfig.key === columnKey) {
      if (sortConfig.order === "none") {
        // → asc に
        setSortConfig({ key: columnKey, order: "asc" });
      } else if (sortConfig.order === "asc") {
        // → desc に
        setSortConfig({ key: columnKey, order: "desc" });
      } else if (sortConfig.order === "desc") {
        // → none に (ソート解除)
        setSortConfig({ key: null, order: "none" });
      }
    } else {
      // 違う列をクリックした場合は、その列を asc でソート開始
      setSortConfig({ key: columnKey, order: "asc" });
    }
  };

  useEffect(() => {
    const { key, order } = sortConfig;
    if (!key || order === "none") {
      // ソート解除の場合は元の並びに戻す
      setQuestionnaireList(originalListRef.current);
    } else {
      // ソート実行
      const sortedData = sortArray(originalListRef.current, key, order);
      setQuestionnaireList(sortedData);
    }
  }, [sortConfig]);

  // ソート状態に応じて三角アイコンを表示
  const renderSortIcon = (columnKey) => {
    // ソート対象の列でなければ何も表示しない
    if (sortConfig.key !== columnKey) {
      return null;
    }
    // 昇順なら▲、降順なら▼
    return sortConfig.order === "asc" ? " ▼" : " ▲";
  };

  return (
    <>
      <h1>アンケート</h1>
      {/* インストラクターリスト */}
      <div className="instructor-list">
        <ul>
          <li onClick={fetchQuestionnaireData}>ALL</li>
          {instructors.map((instructor) => (
            <li
              key={instructor.id}
              onClick={() => handleInstructorClick(instructor.id)}
            >
              {instructor.username}
            </li>
          ))}
        </ul>
      </div>

      <div
        className="graph"
        style={{
          gridTemplateRows: `repeat(${questionnaireList.length + 1}, auto)`,
        }}
      >
        {/* 顧客ID */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li>ID</li>
          {questionnaireList.map((questionnaire, index) => {
            return <li key={`customer-id-${index}`}>{questionnaire.id}</li>;
          })}
        </ul>

        {/* 感想 */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li>感想</li>
          {questionnaireList.map((questionnaire, index) => {
            return (
              <li key={`impression-${index}`}>{questionnaire.impression}</li>
            );
          })}
        </ul>

        {/* インストラクターへのメッセージ */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li>インストラクターへのメッセージ</li>
          {questionnaireList.map((questionnaire, index) => {
            return (
              <li key={`instructor-message-${index}`}>
                {questionnaire.instructor_message}
              </li>
            );
          })}
        </ul>

        {/* 満足度 (ソート可能) */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li
            style={{ cursor: "pointer" }}
            onClick={() => handleSortClick("satisfaction")}
          >
            満足度{renderSortIcon("satisfaction")}
          </li>
          {questionnaireList.map((questionnaire, index) => {
            return (
              <li key={`satisfaction-${index}`}>
                {questionnaire.satisfaction}
              </li>
            );
          })}
        </ul>

        {/* 会場の広さ (ソート可能) */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li
            style={{ cursor: "pointer" }}
            onClick={() => handleSortClick("size")}
          >
            会場の広さ{renderSortIcon("size")}
          </li>
          {questionnaireList.map((questionnaire, index) => {
            return <li key={`size-${index}`}>{questionnaire.size}</li>;
          })}
        </ul>

        {/* 料金 (ソート可能) */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li
            style={{ cursor: "pointer" }}
            onClick={() => handleSortClick("price")}
          >
            料金{renderSortIcon("price")}
          </li>
          {questionnaireList.map((questionnaire, index) => {
            return <li key={`price-${index}`}>{questionnaire.price}</li>;
          })}
        </ul>

        {/* 参加ナンバー */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li>参加ナンバー</li>
          {questionnaireList.map((questionnaire, index) => (
            <li key={`numbers-${index}`}>
              {questionnaire.numbers.map((number, idx) => (
                <React.Fragment key={`numbers-${index}-${idx}`}>
                  {number.studio_name} / {number.lesson_name}
                  <br />
                </React.Fragment>
              ))}
            </li>
          ))}
        </ul>

        {/* インストラクター */}
        <ul style={{ gridRow: `span ${questionnaireList.length + 1}` }}>
          <li>インストラクター</li>
          {questionnaireList.map((questionnaire, index) => (
            <li key={`numbers-${index}`}>
              {questionnaire.numbers.map((number, idx) => (
                <React.Fragment key={`numbers-${index}-${idx}`}>
                  {number.instructor_name}
                  <br />
                </React.Fragment>
              ))}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default QuestionnairePage;
