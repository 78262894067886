"use client";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Header,
  Button,
  Modal,
  Segment,
  Form,
  Divider,
  Radio,
  TextArea,
  Grid,
  Message,
  Loader,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "semantic-ui-css/semantic.min.css";

function BroadcastMessageEditPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEditMode = !!id;

  // フォームの状態
  const [formData, setFormData] = useState({
    text: "",
    deliveryType: "immediate",
    scheduledAt: getNextTimeSlot(),
  });

  const [isSent, setIsSent] = useState(false);
  const [errors, setErrors] = useState({});
  const [textCount, setTextCount] = useState(0);

  // モーダル状態
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);

  // ローディング状態
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // 次の15分枠を取得
  function getNextTimeSlot(baseTime = new Date()) {
    const now = new Date(baseTime);
    const minutes = now.getMinutes();
    const remainder = minutes % 15;
    const minutesToAdd = remainder === 0 ? 15 : 15 - remainder;
    const nextSlot = new Date(now.getTime() + minutesToAdd * 60000);
    nextSlot.setSeconds(0, 0); // 秒とミリ秒をリセット
    return nextSlot;
  }

  // 編集モードの場合、データを取得
  useEffect(() => {
    const fetchMessageData = async () => {
      if (isEditMode) {
        try {
          setLoading(true);
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(
            `${apiUrl}/api/mypage/get_broadcast_message/${id}/`
          );

          if (!response.ok) {
            throw new Error("一斉送信の取得に失敗しました");
          }

          const data = await response.json();
          if (data.status === "success") {
            // APIのレスポンスをフォームデータの形式に変換
            const scheduledAtUTC = data.data.scheduled_at
              ? new Date(data.data.scheduled_at)
              : new Date();

            // 日本時間に調整（+9時間）
            const scheduledAtJST = new Date(
              scheduledAtUTC.getTime() + 9 * 60 * 60 * 1000
            );

            const formattedData = {
              text: data.data.text,
              deliveryType: data.data.delivery_type,
              scheduledAt: scheduledAtJST,
            };

            setFormData(formattedData);
            setTextCount(formattedData.text.length);
            setIsSent(data.data.is_sent);
          } else {
            throw new Error("一斉送信の取得に失敗しました");
          }
        } catch (error) {
          console.error("一斉送信データの取得中にエラーが発生しました:", error);
          setError("一斉送信データの取得に失敗しました。");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMessageData();
  }, [isEditMode, id]);

  // 入力フィールドの変更ハンドラ
  const handleInputChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });

    // 文字数カウントの更新
    if (name === "text") {
      setTextCount(value.length);
    }

    // エラーをクリア
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  // 日付選択の変更ハンドラ
  const handleDateChange = (date) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const selectedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    // 選択された日付が今日の場合
    if (selectedDate.getTime() === today.getTime()) {
      const nextSlot = getNextTimeSlot();
      // 選択された時間が現在時刻より前の場合は、次の15分枠に設定
      if (
        date.getHours() < nextSlot.getHours() ||
        (date.getHours() === nextSlot.getHours() &&
          date.getMinutes() < nextSlot.getMinutes())
      ) {
        date = new Date(nextSlot);
      }
    }

    setFormData({ ...formData, scheduledAt: date });
  };

  // フォームの検証
  const validateForm = () => {
    const newErrors = {};
    const maxTextLength = 100;

    if (!formData.text.trim()) {
      newErrors.text = "本文を入力してください";
    } else if (formData.text.length > maxTextLength) {
      newErrors.text = `本文は${maxTextLength}文字以内で入力してください`;
    }

    if (formData.deliveryType === "scheduled" && !formData.scheduledAt) {
      newErrors.scheduledAt = "配信日時を選択してください";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // 送信処理
  const handleSend = async () => {
    if (validateForm()) {
      setConfirmModalOpen(true);
    }
  };

  // 送信確認
  const confirmSend = async () => {
    if (!validateForm()) return;

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const endpoint = isEditMode
        ? `${apiUrl}/api/mypage/update_broadcast_message/${id}/`
        : `${apiUrl}/api/mypage/create_broadcast_message/`;

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: formData.text,
          delivery_type: formData.deliveryType,
          scheduled_at:
            formData.deliveryType === "scheduled"
              ? formData.scheduledAt.toISOString()
              : null,
          is_draft: false,
          is_sent: formData.deliveryType === "immediate",
          sent_at:
            formData.deliveryType === "immediate"
              ? new Date().toISOString()
              : null,
        }),
      });

      if (!response.ok) {
        throw new Error(
          isEditMode
            ? "一斉送信の更新に失敗しました"
            : "一斉送信の登録に失敗しました"
        );
      }

      setConfirmModalOpen(false);
      navigate("/mypage/broadcast-message-list");
    } catch (error) {
      console.error(
        isEditMode
          ? "一斉送信の更新中にエラーが発生しました:"
          : "一斉送信の登録中にエラーが発生しました:",
        error
      );
      alert(
        isEditMode
          ? "一斉送信の更新に失敗しました。"
          : "一斉送信の登録に失敗しました。"
      );
    }
  };

  const handleSaveDraft = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const endpoint = isEditMode
        ? `${apiUrl}/api/mypage/update_broadcast_message/${id}/`
        : `${apiUrl}/api/mypage/create_broadcast_message/`;

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: formData.text,
          delivery_type: formData.deliveryType,
          scheduled_at:
            formData.deliveryType === "scheduled"
              ? formData.scheduledAt.toISOString()
              : null,
          is_draft: true,
        }),
      });

      if (!response.ok) {
        throw new Error("下書き保存に失敗しました");
      }

      alert("下書きが保存されました");
      navigate("/mypage/broadcast-message-list");
    } catch (error) {
      console.error("下書き保存中にエラーが発生しました:", error);
      alert("下書き保存に失敗しました");
    }
  };

  // キャンセル処理
  const handleCancel = () => {
    // フォームに変更があれば確認モーダルを表示
    setDiscardModalOpen(true);
  };

  // 変更を破棄して戻る
  const confirmDiscard = () => {
    setDiscardModalOpen(false);
    navigate("/mypage/broadcast-message-list");
  };

  return (
    <div className="broadcast-message-edit">
      {loading ? (
        <Loader active inline="centered" content="データを読み込み中..." />
      ) : error ? (
        <Message negative>
          <Message.Header>エラー</Message.Header>
          <p>{error}</p>
          <Button
            primary
            onClick={() => navigate("/mypage/broadcast-message-list")}
          >
            一覧に戻る
          </Button>
        </Message>
      ) : (
        <Grid>
          <Grid.Column width={10}>
            <div className="form-section">
              <div className="page-header">
                <Header as="h1" className="page-title">
                  {isEditMode ? "一斉送信編集" : "新規一斉送信作成"}
                </Header>
              </div>

              <Segment className="form-segment">
                <Form>
                  <Form.Field error={!!errors.text}>
                    <label>
                      本文 <span className="required">*</span>
                    </label>
                    <TextArea
                      name="text"
                      value={formData.text}
                      onChange={handleInputChange}
                      placeholder="メッセージ本文を入力（100文字以内）"
                      rows={5}
                      maxLength={100}
                      className="dark-textarea"
                      disabled={isSent}
                    />
                    <div className="character-count">
                      <span className={textCount > 100 ? "error" : ""}>
                        {textCount}/100
                      </span>
                    </div>
                    {errors.text && <Message error content={errors.text} />}
                  </Form.Field>

                  <Divider />

                  <Form.Field>
                    <label>
                      配信タイプ <span className="required">*</span>
                    </label>
                    <Form.Group inline>
                      <Form.Field>
                        <Radio
                          label="即時配信"
                          name="deliveryType"
                          value="immediate"
                          checked={formData.deliveryType === "immediate"}
                          onChange={handleInputChange}
                          className="dark-radio"
                          disabled={isSent}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="予約配信"
                          name="deliveryType"
                          value="scheduled"
                          checked={formData.deliveryType === "scheduled"}
                          onChange={handleInputChange}
                          className="dark-radio"
                          disabled={isSent}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form.Field>

                  {formData.deliveryType === "scheduled" && (
                    <Form.Field error={!!errors.scheduledAt}>
                      <label>
                        配信日時 <span className="required">*</span>
                      </label>
                      <DatePicker
                        selected={formData.scheduledAt}
                        onChange={handleDateChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="yyyy/MM/dd HH:mm"
                        minDate={new Date()}
                        minTime={
                          formData.scheduledAt.getDate() ===
                          new Date().getDate()
                            ? getNextTimeSlot()
                            : new Date(0, 0, 0, 0, 0)
                        }
                        maxTime={new Date(0, 0, 0, 23, 45)}
                        filterTime={(time) => {
                          if (
                            formData.scheduledAt.getDate() !==
                            new Date().getDate()
                          )
                            return true;
                          const nextSlot = getNextTimeSlot();
                          return time >= nextSlot;
                        }}
                        className="form-control dark-datepicker"
                        disabled={isSent}
                      />
                      {errors.scheduledAt && (
                        <Message error content={errors.scheduledAt} />
                      )}
                    </Form.Field>
                  )}

                  <div className="form-actions">
                    {!isSent && (
                      <Button
                        type="button"
                        onClick={handleCancel}
                        className="cancel-button"
                      >
                        キャンセル
                      </Button>
                    )}
                    {!isSent && (
                      <Button
                        type="button"
                        color="blue"
                        onClick={handleSaveDraft}
                        className="save-draft-button"
                      >
                        下書き保存
                      </Button>
                    )}
                    {!isSent && (
                      <Button
                        type="button"
                        color="green"
                        onClick={handleSend}
                        className="send-button"
                      >
                        {isEditMode
                          ? "更新"
                          : formData.deliveryType === "immediate"
                          ? "送信"
                          : "保存"}
                      </Button>
                    )}
                  </div>
                </Form>
              </Segment>
            </div>
          </Grid.Column>

          <Grid.Column width={6}>
            <div className="preview-section">
              <Segment className="device-preview">
                <div className="device-frame">
                  <div className="device-status-bar">
                    <div className="time">9:41</div>
                  </div>
                  <div className="message-preview">
                    <div className="app-icon"></div>
                    <div className="message-content">
                      <div className="message-header">
                        <div className="app-name">SLASH DANCE</div>
                        <div className="message-time">今</div>
                      </div>
                      <div className="message-title">
                        {formData.text || "メッセージ本文がここに表示されます"}
                      </div>
                    </div>
                  </div>
                </div>
              </Segment>
            </div>
          </Grid.Column>
        </Grid>
      )}

      {/* 送信確認モーダル */}
      <Modal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        size="tiny"
        className="confirm-modal"
      >
        <Modal.Header>
          {formData.deliveryType === "immediate" ? "送信確認" : "保存確認"}
        </Modal.Header>
        <Modal.Content>
          <p>
            {formData.deliveryType === "immediate"
              ? "この一斉送信を送信しますか？"
              : `この一斉送信を ${new Intl.DateTimeFormat("ja-JP", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                  timeZone: "Asia/Tokyo",
                }).format(formData.scheduledAt)} に予約登録しますか？`}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setConfirmModalOpen(false)}>
            キャンセル
          </Button>
          <Button positive onClick={confirmSend}>
            {isEditMode
              ? "更新"
              : formData.deliveryType === "immediate"
              ? "送信"
              : "保存"}
          </Button>
        </Modal.Actions>
      </Modal>

      {/* 変更破棄確認モーダル */}
      <Modal
        open={discardModalOpen}
        onClose={() => setDiscardModalOpen(false)}
        size="tiny"
        className="discard-modal"
      >
        <Modal.Header>変更を破棄</Modal.Header>
        <Modal.Content>
          <p>変更内容が保存されていません。変更を破棄して戻りますか？</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setDiscardModalOpen(false)}>キャンセル</Button>
          <Button negative onClick={confirmDiscard}>
            破棄して戻る
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default BroadcastMessageEditPage;
