import React, { useState, useEffect, useCallback, useRef } from "react";
import { Loader2 } from "lucide-react"; // For loading spinner

// Loading spinner component
const ScrollLoader = () => (
  <div className="scroll-loader">
    <Loader2 className="animate-spin" color="#157edf" size={35} />
  </div>
);

function ChatPage() {
  const [chats, setChats] = useState([]);
  const [chatUsers, setChatUsers] = useState([]);
  const [chatUser, setChatUser] = useState({});
  const [message, setMessage] = useState("");

  const [userPage, setUserPage] = useState(1);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);

  const [chatPage, setChatPage] = useState(1);
  const [hasMoreChats, setHasMoreChats] = useState(true);

  // ▼▼▼ ローディング状態のステート ▼▼▼
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingChats, setIsLoadingChats] = useState(false);

  // ▼▼▼ フィルタ / 検索用 ▼▼▼
  const [filter, setFilter] = useState("");
  // 実際にサーバーへ送る検索キーワード
  const [searchTerm, setSearchTerm] = useState("");
  // ユーザーが入力中の文字列（EnterまでAPI呼ばない）
  const [searchInput, setSearchInput] = useState("");

  // 参照用
  const sidebarRef = useRef(null);
  const chatAreaRef = useRef(null);
  const messagesEndRef = useRef(null);

  const isInitialLoadRef = useRef(true);
  const oldScrollHeightRef = useRef(0);
  const loadingOlderRef = useRef(false);
  const textAreaRef = useRef(null);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [incomingMessage, setIncomingMessage] = useState(null);

  // ▼▼▼ 画像/動画拡大表示用の state ▼▼▼
  // 例: { url: "...", type: "image"|"video" } or null
  const [expandedMedia, setExpandedMedia] = useState(null);

  useEffect(() => {
    console.log(chats);
  }, [chats]);

  // --------------------- フィルタ・検索 --------------------- //
  const handleChangeFilter = (newFilter) => {
    setFilter(newFilter);
    setUserPage(1);
    setHasMoreUsers(true);
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setSearchTerm(searchInput);
      setUserPage(1);
      setHasMoreUsers(true);
    }
  };

  // --------------------- ユーザー一覧の取得 --------------------- //
  const fetchChatUsers = useCallback(
    async (page = 1) => {
      if (isLoadingUsers) return;

      try {
        // 追加読み込み時などでローディング表示
        setIsLoadingUsers(true);

        const apiUrl = process.env.REACT_APP_API_URL;
        const params = new URLSearchParams();
        params.set("page", page);
        if (filter) params.set("filter", filter);
        if (searchTerm) params.set("search", searchTerm);

        const response = await fetch(
          `${apiUrl}/api/mypage/get_chat_users/?${params.toString()}`
        );
        if (!response.ok) throw new Error("ネットワークレスポンスが異常です。");

        const data = await response.json();
        if (data.length < 20) {
          setHasMoreUsers(false);
        }

        data.sort(
          (a, b) => new Date(b.last_inquiry_at) - new Date(a.last_inquiry_at)
        );

        if (page === 1) {
          setChatUsers(data);
        } else {
          // setChatUsers((prev) => [...prev, ...data]);

          setChatUsers((prevUsers) => {
            // prevUsersに存在しないメッセージのみを抽出
            const uniqueUsers = data.filter(
              (user) => !prevUsers.some((prevUser) => prevUser.id === user.id)
            );
            return [...prevUsers, ...uniqueUsers];
          });
        }
      } catch (error) {
        console.error("データの取得中にエラー:", error);
      } finally {
        // 取得が終わったらローディング解除
        setIsLoadingUsers(false);
      }
    },
    [filter, searchTerm]
  );

  const handleScrollUserList = (e) => {
    if (!hasMoreUsers || isLoadingUsers) return;
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      setUserPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    fetchChatUsers(userPage);
  }, [userPage, filter, searchTerm, fetchChatUsers]);

  const handleUserListUpdated = () => {
    setUserPage(1);
    setHasMoreUsers(true);
    fetchChatUsers(1);
  };

  useEffect(() => {
    const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
    const wsAdminList = new WebSocket(`${websocketUrl}/ws/admin_list/`);
    wsAdminList.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.event === "user_list_updated") {
        handleUserListUpdated();
      }
    };
    return () => {
      wsAdminList.close();
    };
  }, [fetchChatUsers]);

  // --------------------- チャット一覧 --------------------- //
  const fetchChats = useCallback(
    async (page = 1) => {
      if (!chatUser.id || isLoadingChats) return;
      try {
        // こちらも読み込み始めにローディング表示
        setIsLoadingChats(true);

        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/mypage/get_chats/`, {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            chat_user_id: chatUser.id,
            page_number: page,
          }),
        });
        if (!response.ok) {
          throw new Error("ネットワークレスポンスが異常です。");
        }

        const data = await response.json();
        if (data.length < 20) {
          setHasMoreChats(false);
        }

        data.sort((a, b) => new Date(a.received_at) - new Date(b.received_at));
        if (page === 1) {
          setChats(data);
        } else {
          setChats((prevChats) => {
            // prevChatsに存在しないメッセージのみを抽出
            const uniqueMessages = data.filter(
              (msg) => !prevChats.some((prevMsg) => prevMsg.id === msg.id)
            );
            return [...uniqueMessages, ...prevChats];
          });
        }
      } catch (error) {
        console.error("チャット一覧取得中にエラー:", error);
      } finally {
        // 取得が終わったらローディング解除
        setIsLoadingChats(false);
        // loadingOlderRef.current = false;
      }
    },
    [chatUser.id]
  );

  const handleScrollChatArea = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop <= 10 && hasMoreChats && !loadingOlderRef.current) {
      oldScrollHeightRef.current = scrollHeight;
      loadingOlderRef.current = true;
      setChatPage((prev) => prev + 1);
    }
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setShouldAutoScroll(true);
      setIncomingMessage(null);
    } else {
      setShouldAutoScroll(false);
    }
  };

  useEffect(() => {
    fetchChats(chatPage);
  }, [chatPage, fetchChats]);

  useEffect(() => {
    if (chatUser.id) {
      setChatPage(1);
      setHasMoreChats(true);
      isInitialLoadRef.current = true;
      fetchChats(1);
      setIncomingMessage(null);
    } else {
      setChats([]);
    }
  }, [chatUser, fetchChats]);

  // --------------------- WebSocket で個別チャットを受信 --------------------- //
  useEffect(() => {
    if (!chatUser.id) return;
    const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
    const ws = new WebSocket(`${websocketUrl}/ws/chat/${chatUser.id}/`);
    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.event === "new_message") {
        setChats((prev) => [...prev, data.chat]);
        if (data.chat.is_from_admin === false) {
          markChatUserRead(data.chat.user_id);
          if (!shouldAutoScroll) {
            setIncomingMessage({
              text: data.chat.text,
              userName: chatUser.name ?? "",
            });
          }
        }
      } else if (data.event === "unsend") {
        setChats((prev) =>
          prev.map((chat) =>
            chat.id === data.chat.id ? { ...chat, delete_flg: true } : chat
          )
        );
      }
    };
    return () => {
      ws.close();
    };
  }, [chatUser, shouldAutoScroll]);

  // --------------------- チャットが更新されたら下端スクロール --------------------- //
  useEffect(() => {
    const chatContainer = chatAreaRef.current;
    if (!chatContainer) return;

    if (loadingOlderRef.current) {
      const newScrollHeight = chatContainer.scrollHeight;
      const diff = newScrollHeight - oldScrollHeightRef.current;
      chatContainer.scrollTop = diff;
      loadingOlderRef.current = false;
      return;
    }
    if (isInitialLoadRef.current) {
      setTimeout(() => {
        scrollToBottom();
        isInitialLoadRef.current = false;
      }, 300);
      return;
    }
    if (shouldAutoScroll) {
      scrollToBottom();
    }
  }, [chats, shouldAutoScroll]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  // --------------------- 既読 / ユーザー選択 --------------------- //
  const handleSelectUser = async (selectedUser) => {
    if (!selectedUser.is_read) {
      await markChatUserRead(selectedUser.id);
    }
    setChatUser(selectedUser);
  };

  const markChatUserRead = async (user_id) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      await fetch(`${apiUrl}/api/mypage/mark_chat_user_read/`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ chat_user_id: user_id }),
      });
    } catch (err) {
      console.error("[handleSelectUser] 既読API失敗:", err);
    }
  };

  // --------------------- メッセージ送信 / 取消 --------------------- //
  const handleSaveChat = async () => {
    if (!chatUser.id || !message.trim()) return;
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/mypage/save_chat/`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          chat_user_id: chatUser.id,
          message: message,
          is_from_admin: true,
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }
      setMessage("");

      // ▼▼▼ テキストエリアの高さを初期状態に戻す ▼▼▼
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.overflowY = "hidden";
      }

      scrollToBottom();
    } catch (error) {
      alert(error);
      console.error("[handleSaveChat] fetch error:", error);
    }
  };

  const handleUnsend = async (messageId) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/mypage/unsend_chat/`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ message_id: messageId }),
      });
      if (!response.ok) {
        throw new Error("メッセージの送信取消に失敗しました。");
      }
    } catch (error) {
      console.error("[handleUnsend] エラー:", error);
    }
  };

  // --------------------- 新着メッセージ通知バーをクリック --------------------- //
  const handleClickIncoming = () => {
    scrollToBottom();
    setIncomingMessage(null);
  };

  // --------------------- 要対応 / 対応済 --------------------- //
  const handleChangeStatus = async (newStatus) => {
    if (!chatUser.id) return;
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const res = await fetch(`${apiUrl}/api/mypage/change_chat_user_status/`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          chat_user_id: chatUser.id,
          status: newStatus,
        }),
      });
      if (!res.ok) {
        throw new Error("ステータス更新に失敗しました");
      }
      setChatUser((prev) => ({ ...prev, status: newStatus }));
    } catch (err) {
      console.error(err);
    }
  };

  // --------------------- 日時フォーマット --------------------- //
  const formatDate = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);

    const isToday =
      date.getFullYear() === now.getFullYear() &&
      date.getMonth() === now.getMonth() &&
      date.getDate() === now.getDate();
    const isSameYear = date.getFullYear() === now.getFullYear();
    const weekdayNames = ["日", "月", "火", "水", "木", "金", "土"];
    const weekday = weekdayNames[date.getDay()];
    const zeroPad = (num) => num.toString().padStart(2, "0");

    const hours = zeroPad(date.getHours());
    const minutes = zeroPad(date.getMinutes());

    if (isToday) {
      return `${hours}:${minutes}`;
    } else {
      if (isSameYear) {
        const month = zeroPad(date.getMonth() + 1);
        const day = zeroPad(date.getDate());
        return `${month}/${day}(${weekday}) ${hours}:${minutes}`;
      } else {
        const year = date.getFullYear();
        const month = zeroPad(date.getMonth() + 1);
        const day = zeroPad(date.getDate());
        return `${year}/${month}/${day}(${weekday}) ${hours}:${minutes}`;
      }
    }
  };

  // --------------------- URLをリンク化する関数 (text用) --------------------- //
  const linkifyText = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        return part;
      }
    });
  };

  // --------------------- textareaの高さを計算、最大値を超えないよう調整 --------------------- //
  const handleTextAreaChange = (e) => {
    const el = textAreaRef.current;
    setMessage(e.target.value);

    // いったん高さを "auto" にしてから scrollHeight を計測
    el.style.height = "auto";

    // “画面の半分の高さ” など、お好みのピクセル値 or vh を最大値に
    const maxHeight = window.innerHeight / 2.5; // 画面の半分
    const scrollHeight = el.scrollHeight;

    if (scrollHeight > maxHeight) {
      // 最大値を超えるならスクロールさせる
      el.style.height = `${maxHeight}px`;
      el.style.overflowY = "auto";
    } else {
      // まだ最大値より小さい場合はそのまま
      el.style.height = `${scrollHeight}px`;
      el.style.overflowY = "hidden";
    }
  };

  // --------------------- メディアをクリックしたら拡大 --------------------- //
  const handleOpenMedia = (type, url) => {
    setExpandedMedia({ type, url });
  };

  // モーダルを閉じる
  const handleCloseMedia = () => {
    setExpandedMedia(null);
  };

  // --------------------- レンダリング --------------------- //
  return (
    <section className="chat-container">
      {/* サイドバー */}
      <div className="sidebar-container">
        <div className="sidebar-header">
          <select
            value={filter}
            onChange={(e) => handleChangeFilter(e.target.value)}
          >
            <option value="">すべて</option>
            <option value="receiving">受信</option>
            <option value="unread">未読</option>
            <option value="required">要対応</option>
            <option value="completed">対応済</option>
          </select>
          <div className="search-wrapper">
            <input
              type="text"
              value={searchInput}
              onChange={handleSearchInputChange}
              onKeyDown={handleSearchKeyDown}
              placeholder="検索ワード (Enterで検索)"
            />
          </div>
        </div>

        <div
          className="user-list-scroll"
          style={{ overflowY: "auto", height: "calc(100vh - 50px)" }}
          ref={sidebarRef}
          onScroll={handleScrollUserList}
        >
          <ul className="user-list">
            {chatUsers.map((u) => (
              <li
                key={u.id}
                className={`user-item ${chatUser.id === u.id ? "active" : ""}`}
                onClick={() => handleSelectUser(u)}
              >
                {u.picture_url ? (
                  <figure className="user-avatar-img">
                    <img src={u.picture_url} alt="" />
                  </figure>
                ) : (
                  <div className="user-avatar">{u.name?.charAt(0) || "?"}</div>
                )}

                <div className="user-info">
                  <div className="user-name">
                    {u.name}
                    {!u.is_read && <span className="unread">●</span>}
                  </div>
                  <div className="user-source">
                    （{u.inquiry_source}）
                    {u.status !== null && (
                      <span
                        className={`user-status-badge ${
                          u.status === 1 ? "required" : "completed"
                        }`}
                      >
                        {u.status === 1 ? "要対応" : "対応済"}
                      </span>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>

          {/* Users Loading Indicator */}
          {isLoadingUsers && <ScrollLoader />}
        </div>
      </div>

      {/* チャットエリア */}
      <div className="chat-area">
        {chatUser.id ? (
          <>
            <div className="chat-header">
              <p>
                {chatUser.name && `${chatUser.name}`}
                <span>（{chatUser.inquiry_source}）</span>
              </p>
              <div className="status-buttons">
                <button
                  className={`status-btn required ${
                    chatUser.status === 1 ? "active" : ""
                  }`}
                  onClick={() => handleChangeStatus(1)}
                >
                  要対応
                </button>
                <button
                  className={`status-btn completed ${
                    chatUser.status === 0 ? "active" : ""
                  }`}
                  onClick={() => handleChangeStatus(0)}
                >
                  対応済
                </button>
              </div>
            </div>

            <div
              className="chat-messages"
              ref={chatAreaRef}
              onScroll={handleScrollChatArea}
            >
              {/* Chats Loading Indicator */}
              {isLoadingChats && <ScrollLoader />}

              <ul className="message-list">
                {chats.map((chat) => (
                  <li
                    key={chat.id}
                    className={`message-item ${
                      chat.is_from_admin ? "admin" : "user"
                    }`}
                  >
                    {/* ▼▼▼ message_type を判定して表示内容を切り替え ▼▼▼ */}
                    {chat.message_type === "text" && (
                      <div
                        className={`message-bubble ${
                          chat.delete_flg && "deleted"
                        }`}
                      >
                        {linkifyText(chat.text || "")}
                      </div>
                    )}

                    {chat.message_type === "image" && !chat.delete_flg && (
                      <div className="message-bubble image-bubble">
                        {/* サムネイル表示 → クリックで拡大 */}
                        <img
                          src={chat.s3_url}
                          alt=""
                          onClick={() => handleOpenMedia("image", chat.s3_url)}
                        />
                      </div>
                    )}

                    {chat.message_type === "video" && !chat.delete_flg && (
                      <div className="message-bubble video-bubble">
                        {/* サムネイル代わりに小さめにvideo or poster属性? */}
                        <video
                          src={chat.s3_url}
                          width="200"
                          onClick={() => handleOpenMedia("video", chat.s3_url)}
                        />
                      </div>
                    )}

                    {/* もし削除済みなら */}
                    {chat.delete_flg && chat.message_type !== "text" && (
                      <div className="message-bubble deleted">
                        削除されたメッセージです
                      </div>
                    )}

                    <div className="message-time">
                      {formatDate(chat.received_at)}
                    </div>

                    {chat.delete_flg ? (
                      <div
                        className={`message-deleted ${
                          chat.is_from_admin ? "admin" : "user"
                        }`}
                      >
                        メッセージの送信を取消しました
                      </div>
                    ) : (
                      // 送信取消ボタン
                      chat.is_from_admin && (
                        <div className="message-actions">
                          <button
                            className="unsend-button"
                            onClick={() => handleUnsend(chat.id)}
                          >
                            送信取消
                          </button>
                        </div>
                      )
                    )}
                  </li>
                ))}
                <div ref={messagesEndRef} />
              </ul>
            </div>

            {incomingMessage && (
              <div className="incoming-notice" onClick={handleClickIncoming}>
                <span className="arrow">↓</span>
                <strong>新着メッセージ：</strong>
                {incomingMessage.text}
              </div>
            )}

            <div className="chat-input">
              <textarea
                ref={textAreaRef}
                className="chat-textarea"
                value={message}
                onChange={handleTextAreaChange}
                placeholder="メッセージを入力..."
                rows={3}
              />
              <div className="chat-controls">
                <button
                  className="send-button"
                  onClick={handleSaveChat}
                  disabled={!message.trim()}
                >
                  送信
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="empty-state">
            <div className="empty-state-icon">💬</div>
            <div className="empty-state-text">
              左側のユーザーリストからチャットを選択してください
            </div>
          </div>
        )}
      </div>

      {/* ▼▼▼ モーダルで画像/動画を拡大表示 ▼▼▼ */}
      {expandedMedia && (
        <div className="media-modal-overlay" onClick={handleCloseMedia}>
          <div
            className="media-modal-content"
            onClick={(e) => e.stopPropagation()} // 背景クリックで閉じるが中クリックは閉じない
          >
            <button className="media-modal-close" onClick={handleCloseMedia}>
              ×
            </button>

            {expandedMedia.type === "image" ? (
              <img src={expandedMedia.url} alt="" className="media-modal-img" />
            ) : (
              <video
                src={expandedMedia.url}
                controls
                className="media-modal-video"
              />
            )}

            {/* ダウンロードボタン */}
            <a
              href={expandedMedia.url}
              download
              className="media-modal-download"
              target="_blank"
            >
              ダウンロード
            </a>
          </div>
        </div>
      )}
    </section>
  );
}

export default ChatPage;
