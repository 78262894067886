import React, { useState, useEffect, useCallback } from "react";
import { Loader, Plus, Trash2, Image as ImageIcon, X } from "lucide-react";
import { Input } from "semantic-ui-react";

function BannerListPage() {
  const [banners, setBanners] = useState([]);
  // ファイル本体を保持する
  const [bannerFiles, setBannerFiles] = useState({});
  // リンクURLを保持
  const [bannerLinks, setBannerLinks] = useState({});
  // バナーごとのプレビュー用 Base64 URL を保持
  const [bannerPreviews, setBannerPreviews] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  // 新規作成用のモーダル関連
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newBannerFile, setNewBannerFile] = useState(null);
  const [newBannerLink, setNewBannerLink] = useState("");
  const [newBannerPreview, setNewBannerPreview] = useState("");

  /**
   * バナー一覧を取得
   */
  const fetchBannersData = useCallback(async () => {
    try {
      setIsLoading(true);
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/mypage/get_banners/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setBanners(data);

      // 取得したバナーのリンクURLを state に反映
      const initialLinks = {};
      data.forEach((b) => {
        initialLinks[b.id] = b.link_url || "";
      });
      setBannerLinks(initialLinks);
      setBannerPreviews({});
      setBannerFiles({});
    } catch (error) {
      console.error("バナー一覧取得中にエラー:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchBannersData();
  }, [fetchBannersData]);

  /**
   * 既存バナーのファイルを選択したとき
   */
  const handleFileChange = (bannerId, e) => {
    const file = e.target.files[0] || null;
    setBannerFiles((prev) => ({
      ...prev,
      [bannerId]: file,
    }));

    // プレビューのために FileReader で base64 に変換
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerPreviews((prev) => ({
          ...prev,
          [bannerId]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setBannerPreviews((prev) => {
        const { [bannerId]: _, ...rest } = prev;
        return rest;
      });
    }
  };

  /**
   * リンクURLが変更されたとき
   */
  const handleLinkChange = (bannerId, e) => {
    const value = e.target.value;
    setBannerLinks((prev) => ({
      ...prev,
      [bannerId]: value,
    }));
  };

  /**
   * 既存バナーを更新
   */
  const handleUpdate = async (bannerId) => {
    setIsLoading(true);
    const file = bannerFiles[bannerId] || null;
    const linkUrl = bannerLinks[bannerId] || "";

    const formData = new FormData();
    formData.append("banner_id", bannerId);
    formData.append("link_url", linkUrl);
    if (file) {
      formData.append("images", file);
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/mypage/update_banner/`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();

      if (result.status === "success") {
        alert("バナーを更新しました");
        fetchBannersData();
      } else {
        alert("更新に失敗しました: " + (result.message || ""));
      }
    } catch (error) {
      alert("登録に失敗しました: " + error.message);
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * バナーを削除
   */
  const handleDelete = async (bannerId) => {
    if (!window.confirm("このバナーを削除してもよろしいですか？")) {
      return;
    }
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/mypage/delete_banner/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ banner_id: bannerId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.status === "success") {
        alert("バナーを削除しました");
        fetchBannersData();
      } else {
        alert("削除に失敗しました: " + (result.message || ""));
      }
    } catch (error) {
      alert("削除に失敗しました: " + error.message);
      console.error("There was a problem with the delete operation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 新規バナー作成
   */
  const handleCreate = async () => {
    if (!newBannerFile) {
      alert("画像を選択してください");
      return;
    }

    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      // バナーの空レコードを作る
      const response = await fetch(`${apiUrl}/api/mypage/create_banner/`, {
        method: "POST",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.status === "success") {
        // 新しく作成されたバナーIDを使って画像とリンクを更新
        const formData = new FormData();
        formData.append("banner_id", result.banner_id);
        formData.append("link_url", newBannerLink);
        formData.append("images", newBannerFile);

        const updateResponse = await fetch(
          `${apiUrl}/api/mypage/update_banner/`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!updateResponse.ok) {
          throw new Error(`HTTP error! status: ${updateResponse.status}`);
        }

        const updateResult = await updateResponse.json();
        if (updateResult.status === "success") {
          alert("新しいバナーを作成しました");
          setIsModalOpen(false);
          setNewBannerFile(null);
          setNewBannerLink("");
          setNewBannerPreview("");
          fetchBannersData();
        } else {
          alert(
            "バナー情報の更新に失敗しました: " + (updateResult.message || "")
          );
        }
      } else {
        alert("作成に失敗しました: " + (result.message || ""));
      }
    } catch (error) {
      alert("作成に失敗しました: " + error.message);
      console.error("There was a problem with the create operation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 新規バナー用ファイルを選択したとき
   */
  const handleNewBannerFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewBannerFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewBannerPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <section>
      <div className="container">
        {/* ローディング表示 */}
        {isLoading && (
          <div className="loading-overlay">
            <Loader className="loading-spinner" />
          </div>
        )}

        {/* 新規作成用モーダル */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <div className="modal-header">
                <h2>新規バナー作成</h2>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="modal-close"
                >
                  <X />
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label">バナー画像</label>
                  <div className="image-upload">
                    {newBannerPreview ? (
                      <div className="image-preview">
                        <img src={newBannerPreview} alt="新規バナー" />
                        <div className="image-overlay">
                          <label className="upload-label">
                            <ImageIcon />
                            画像を変更
                            <input
                              type="file"
                              className="file-input"
                              onChange={handleNewBannerFileChange}
                              accept="image/*"
                            />
                          </label>
                        </div>
                      </div>
                    ) : (
                      <label className="empty-upload">
                        <div className="empty-upload-content">
                          <ImageIcon />
                          <span className="empty-upload-text">
                            画像を選択してください
                          </span>
                          <input
                            type="file"
                            className="file-input"
                            onChange={handleNewBannerFileChange}
                            accept="image/*"
                          />
                        </div>
                      </label>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">リンクURL</label>
                  <Input
                    name="newBannerLink"
                    value={newBannerLink}
                    onChange={(e) => setNewBannerLink(e.target.value)}
                    placeholder="https://example.com"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button onClick={handleCreate} className="submit-button">
                  作成
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="content-wrapper">
          <div className="header">
            <h1 className="page-title">バナー管理</h1>
            <button
              onClick={() => setIsModalOpen(true)}
              className="create-button"
            >
              <Plus />
              新規バナー作成
            </button>
          </div>

          <div className="banner-grid">
            {banners.map((banner) => {
              const preview = bannerPreviews[banner.id];
              return (
                <div key={banner.id} className="banner-card">
                  <div className="banner-content">
                    <div className="banner-header">
                      <button
                        onClick={() => handleDelete(banner.id)}
                        className="delete-button"
                      >
                        <Trash2 />
                      </button>
                    </div>

                    {/* 画像 */}
                    <div className="form-group">
                      <label className="form-label">バナー画像</label>
                      <div className="image-upload">
                        {preview ? (
                          <div className="image-preview">
                            <img src={preview} alt="バナー変更プレビュー" />
                            <div className="image-overlay">
                              <label className="upload-label">
                                <ImageIcon />
                                画像を変更
                                <input
                                  type="file"
                                  className="file-input"
                                  onChange={(e) =>
                                    handleFileChange(banner.id, e)
                                  }
                                  accept="image/*"
                                />
                              </label>
                            </div>
                          </div>
                        ) : banner.image_url ? (
                          <div className="image-preview">
                            <img src={banner.image_url} alt="バナー" />
                            <div className="image-overlay">
                              <label className="upload-label">
                                <ImageIcon />
                                画像を変更
                                <input
                                  type="file"
                                  className="file-input"
                                  onChange={(e) =>
                                    handleFileChange(banner.id, e)
                                  }
                                  accept="image/*"
                                />
                              </label>
                            </div>
                          </div>
                        ) : (
                          <label className="empty-upload">
                            <div className="empty-upload-content">
                              <ImageIcon />
                              <span className="empty-upload-text">
                                画像を選択してください
                              </span>
                              <input
                                type="file"
                                className="file-input"
                                onChange={(e) => handleFileChange(banner.id, e)}
                                accept="image/*"
                              />
                            </div>
                          </label>
                        )}
                      </div>
                    </div>

                    {/* リンクURL */}
                    <div className="form-group">
                      <label
                        htmlFor={`link-${banner.id}`}
                        className="form-label"
                      >
                        リンクURL
                      </label>
                      <Input
                        name={`link-${banner.id}`}
                        value={bannerLinks[banner.id] || ""}
                        onChange={(e) => handleLinkChange(banner.id, e)}
                      />
                    </div>

                    {/* 更新ボタン */}
                    <div style={{ textAlign: "right" }}>
                      <button
                        onClick={() => handleUpdate(banner.id)}
                        className="submit-button"
                      >
                        更新
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default BannerListPage;
