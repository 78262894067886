import React, { useState, useEffect } from "react";
import { useLoginCheck } from "../../hooks/useLoginCheck";
import { useLoading } from "../../contexts/LoadingContext";

import "../../styles/scss/restock.scss";

function RestockPage() {
  useLoginCheck();
  const [restockRequestList, setRestockRequestList] = useState([]);
  const { setIsLoading } = useLoading();
  const [itemListLength, setItemListLength] = useState([]); // liの数
  const [userListLength, setUserListLength] = useState([]); // liの数
  const [isOpen, setIsOpen] = useState([]); // リクエスト一覧の開閉

  // 再入荷リクエスト一覧の取得
  const fetchRestockData = async () => {
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_restock_notices/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setRestockRequestList(data);
      console.log(data);
      // 期間ごとにitemsの数を取得
      const getItemsCountByWeek = (data) => {
        return data.map((week) => (week.items || []).length + 1); // データの数にヘッダーを追加
      };
      setItemListLength(getItemsCountByWeek(data));
      // 期間ごとにusersの数を取得
      const getUsersCountByWeek = (data) => {
        return data.map((week) => (week.users || []).length + 1); // データの数にヘッダーを追加
      };
      setUserListLength(getUsersCountByWeek(data));
      // リクエスト一覧の状態
      setIsOpen(data.map(() => false));
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
    setIsLoading(false);
  };

  // メール送信
  async function sendNoticeEmail(request_users, item) {
    const confirmSend = window.confirm(
      `${item.product_name} ${item.color}/${item.size}\n商品の再入荷通知を送信しますか？`
    );
    if (!confirmSend) return;
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/send_restock_notices/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          request_users: request_users,
          product_id: item.product_id,
          product_name: item.product_name,
          color: item.color,
          size: item.size,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      alert("メールを送信しました。");
      fetchRestockData();
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }

  // メール送信
  async function sendEmail(restockRequest) {
    const confirmSend = window.confirm(`メールを送信しますか？`);
    if (!confirmSend) return;
    console.log(restockRequest);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/send_presale_notices/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          restockRequest: restockRequest,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      alert("メールを送信しました。");
      fetchRestockData();
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }

  useEffect(() => {
    fetchRestockData();
  }, []);

  return (
    <>
      <h1>再入荷リクエスト</h1>
      {restockRequestList &&
        restockRequestList.map((restockRequest, index) => (
          <div className="period" key={`period-${index}`}>
            <p>
              申込期間 {restockRequest.start_week}〜{restockRequest.end_week}
            </p>
            <div
              className="graph"
              style={{
                gridTemplateRows: `repeat(${itemListLength[index]}, auto)`,
              }}
            >
              <ul style={{ gridRow: `span ${itemListLength[index]}` }}>
                <li key={`header-product-${index}`}>商品</li>
                {restockRequest.items.map((item) => (
                  <li key={`product-${item.variant_id}`}>
                    {item.product_name}
                  </li>
                ))}
              </ul>
              <ul style={{ gridRow: `span ${itemListLength[index]}` }}>
                <li key={`header-color-${index}`}>色</li>
                {restockRequest.items.map((item) => (
                  <li key={`color-${item.variant_id}`}>{item.color}</li>
                ))}
              </ul>
              <ul style={{ gridRow: `span ${itemListLength[index]}` }}>
                <li key={`header-size-${index}`}>サイズ</li>
                {restockRequest.items.map((item) => (
                  <li key={`size-${item.variant_id}`}>{item.size}</li>
                ))}
              </ul>
              <ul
                className="calc_total_list"
                style={{ gridRow: `span ${itemListLength[index]}` }}
              >
                <li key={`header-total-${index}`}>個数</li>
                {restockRequest.items.map((item) => (
                  <li key={`total-${item.variant_id}`}>
                    {item.request_users.length}
                  </li>
                ))}
              </ul>
              <ul
                style={{
                  gridRow: `span ${itemListLength[index]}`,
                  display: "none",
                }}
              >
                <li key={`header-email-${index}`}>メール</li>
                {restockRequest.items.map((item) => (
                  <li key={`email-${item.variant_id}`}>
                    <button
                      type="button"
                      onClick={() => {
                        sendNoticeEmail(item.request_users, item);
                      }}
                      disabled={!item.has_pending}
                    >
                      送信
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="user-list-header">
              <div
                type="button"
                className="toggle"
                onClick={() => {
                  let array = isOpen.map((x) => x);
                  array[index] = !array[index];
                  setIsOpen(array);
                }}
              >
                {isOpen[index] ? <span>▼</span> : <span>▶︎</span>}
                リクエスト一覧{" "}
                {restockRequest && `(${restockRequest.users.length})`}
              </div>
              <div className="button-wrap">
                <button
                  type="button"
                  onClick={() => {
                    sendEmail(restockRequest);
                  }}
                  disabled={
                    !restockRequest.items.some((item) => item.has_pending)
                  }
                >
                  メール送信
                </button>
              </div>
            </div>

            <div
              className="graph user-list"
              style={{
                gridTemplateRows: `repeat(${userListLength[index]}, auto)`,
              }}
            >
              {isOpen[index] ? (
                <>
                  <ul style={{ gridRow: `span ${userListLength[index]}` }}>
                    <li key={`header-mail-${index}`}>メール</li>
                    {restockRequest.users.map((user) => (
                      <li key={`user-mail-${user.mail}`}>{user.mail}</li>
                    ))}
                  </ul>
                  <ul style={{ gridRow: `span ${userListLength[index]}` }}>
                    <li key={`header-user-items-${index}`}>商品</li>
                    {restockRequest.users.map((user) => (
                      <li key={`user-items-${user.mail}`}>
                        {user.items.map((item, itemIndex) => (
                          <React.Fragment
                            key={`user-item-${user.mail}-${itemIndex}`}
                          >
                            {item.variants.length >= 2 ? (
                              item.variants.map((variant, variantIndex) => (
                                <span
                                  key={`variant-${user.mail}-${itemIndex}-${variantIndex}`}
                                >
                                  {variant.product_name}
                                  {variant.size} / {variant.color}
                                </span>
                              ))
                            ) : (
                              <span>
                                {item.name} {item.size} / {item.color}
                              </span>
                            )}
                          </React.Fragment>
                        ))}
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
            </div>
          </div>
        ))}
    </>
  );
}

export default RestockPage;
