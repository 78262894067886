import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Grid,
  GridRow,
  GridColumn,
  Header,
  Input,
  Image,
  Icon,
  Label,
  Message,
  MessageHeader,
  Segment,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

function VisualComponent({
  onUpdate,
  hashtagText = null,
  parentUpdated = null,
}) {
  const [hashtagMasterList, setHashtagMasterList] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [images, setImages] = useState([]);
  const [filteredHashtags, setFilteredHashtags] = useState([]);

  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedHashTags, setSelectedHashTags] = useState([]);

  const [hashtagInput, setHashtagInput] = useState("");

  const [message, setMessage] = useState(null);

  const [deletedTags, setDeletedTags] = useState([]);

  const [page, setPage] = useState(1); // ページネーション用
  const [searchWord, setSearchWord] = useState(""); // 検索文字

  // 入力欄を基にタグをstateに保存する関数
  const handleAddHashtag = () => {
    if (hashtagInput) {
      // 一致するハッシュタグを検索
      const matchedHashtag = hashtagMasterList.find(
        (tag) => tag.text === hashtagInput
      );

      setSelectedHashTags((prevTags) => {
        // 既に削除されたタグのリストにあるか確認
        const existingDeletedTagIndex = deletedTags.findIndex(
          (tag) => tag.text === hashtagInput
        );

        if (existingDeletedTagIndex !== -1) {
          // 削除されたタグのリストからタグを戻す
          const existingDeletedTag = deletedTags[existingDeletedTagIndex];
          setDeletedTags((prevDeletedTags) =>
            prevDeletedTags.filter(
              (_, index) => index !== existingDeletedTagIndex
            )
          );
          return [...prevTags, existingDeletedTag];
        } else {
          // 既に選択されたタグのリストにあるか確認
          const isTagAlreadyAdded = prevTags.some(
            (tag) => tag.text === hashtagInput
          );

          if (!isTagAlreadyAdded) {
            // 一致するものがある場合はそのレコードを、なければ新しいオブジェクトを追加
            const newTag = matchedHashtag
              ? {
                  id: null,
                  master_id: matchedHashtag.id,
                  text: hashtagInput,
                  order: null,
                }
              : {
                  id: null,
                  master_id: null,
                  text: hashtagInput,
                  order: null,
                };
            return [...prevTags, newTag];
          }
        }

        return prevTags; // 既に存在する場合は何も追加しない
      });

      setHashtagInput("");
    }
  };

  // タグを削除する関数
  const handleRemoveTag = (tagToRemove) => {
    setSelectedHashTags((prevTags) =>
      prevTags.filter((tag) => tag !== tagToRemove)
    );
    setDeletedTags((prevDeletedTags) => [...prevDeletedTags, tagToRemove]);
  };

  // タグ入力欄でエンターを押下した際にstateに保持する関数を呼び出す
  const handleHashtagInputKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddHashtag();
      setHashtagInput("");
    }
  };

  // インプットの状態を監視
  const handleHashtagInputChange = (event) => {
    setHashtagInput(event.target.value);
  };

  // 検索欄の状態を監視
  const handleSearchChange = (event) => {
    setSearchWord(event.target.value);
  };

  // 画像リストの取得
  const fetchImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: page,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      if (page === 1) {
        setImages(data);
      } else {
        setImages((prevImages) => {
          const newImages = data.filter(
            (newImage) =>
              !prevImages.some((prevImage) => prevImage.id === newImage.id)
          );
          return [...prevImages, ...newImages];
        });
      }
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [page]);

  // 画像の検索
  const searchImagesData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: 1,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setImages(data);
      setPage(1);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // ハッシュタグマスタの取得
  const fetchHashtagMasterData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_hashtag_master/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setHashtagMasterList(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // ハッシュタグの保存
  const saveHashTags = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/save_hashtags/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target_id: selectedMedia.id,
          hashtags: selectedHashTags,
          type: "Image",
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      setMessage("更新が完了しました。");
      fetchHashtagMasterData();
      onUpdate(); // 親コンポーネントの更新関数を呼び出す
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // stateにハッシュタグをセット
  const handleSelectedHashTags = (hashtagMaster) => {
    // 既に削除されたタグのリストにあるか確認
    const existingDeletedTagIndex = deletedTags.findIndex(
      (tag) => tag.master_id === hashtagMaster.id
    );

    if (existingDeletedTagIndex !== -1) {
      // 削除されたタグのリストからタグを戻す
      const existingDeletedTag = deletedTags[existingDeletedTagIndex];
      setDeletedTags((prevDeletedTags) =>
        prevDeletedTags.filter((_, index) => index !== existingDeletedTagIndex)
      );
      setSelectedHashTags((prevTags) => [...prevTags, existingDeletedTag]);
    } else {
      // 既に選択されたタグのリストに含まれているか確認
      const exists = selectedHashTags.some(
        (tag) => tag.master_id === hashtagMaster.id
      );

      if (!exists) {
        // 存在しない場合は新たに追加
        setSelectedHashTags((prevTags) => [
          ...prevTags,
          {
            id: null,
            master_id: hashtagMaster.id,
            text: hashtagMaster.text,
            order: null,
          },
        ]);
      }
    }
  };

  // stateに画像をセット
  const handleImageChange = (event) => {
    setNewImages([...event.target.files]);
  };

  // 画像をAPIに送信
  const handleImageSubmit = async () => {
    const formData = new FormData();
    newImages.forEach((image) => {
      formData.append("images", image);
    });

    if (hashtagText != null) {
      formData.append("hashtag_text", hashtagText);
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/image_upload/`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setMessage("アップロードが完了しました");
      if (page === 1) {
        fetchImagesData();
      } else {
        setPage(1);
      }
      onUpdate(); // 親コンポーネントの更新関数を呼び出す
    } catch (error) {
      alert("登録に失敗しました");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // ビデオかどうか
  function isVideo(url) {
    // 動画ファイルの拡張子リスト
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    // URLの末尾の拡張子を確認
    return videoExtensions.some((extension) => url.endsWith(extension));
  }

  // メッセージの制御
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
      }, 5000); // メッセージを5秒後に消す

      return () => clearTimeout(timer); // コンポーネントのクリーンアップ時にタイマーをクリア
    }
  }, [message]);

  // 入力されたテキストに基づいて hashtagMasterList からフィルタリング
  useEffect(() => {
    const results = hashtagMasterList.filter((hashtag) =>
      hashtag.text.toLowerCase().includes(hashtagInput.toLowerCase())
    );
    setFilteredHashtags(results);
  }, [hashtagInput, hashtagMasterList]);

  // 画像リストの取得
  useEffect(() => {
    fetchImagesData();
  }, [fetchImagesData]);

  // タグマスタの取得
  useEffect(() => {
    fetchHashtagMasterData();
  }, []);

  // 親で更新があった場合にstateを更新
  useEffect(() => {
    if (parentUpdated === true) {
      fetchHashtagMasterData();
      fetchImagesData();
    }
  }, [parentUpdated]);

  return (
    <div className="visual-component">
      {message && (
        <Message positive>
          <MessageHeader>{message}</MessageHeader>
        </Message>
      )}
      <div className="searchWrap">
        <Input
          icon={{
            name: "search",
            link: true,
            onClick: () => searchImagesData(),
          }}
          placeholder="検索"
          onChange={handleSearchChange}
        />
      </div>
      <Input type="file" multiple onChange={handleImageChange} />
      <Button primary onClick={handleImageSubmit}>
        アップロード
      </Button>

      {/* 画像リスト */}
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column className="scroll">
            <ul className="imgList">
              {images &&
                images.map((image) =>
                  isVideo(image.url) ? (
                    <li
                      key={image.id}
                      onClick={() => {
                        setSelectedMedia(image);
                        setSelectedHashTags(image.hashtags);
                        setDeletedTags([]);
                      }}
                    >
                      <video>
                        <source src={image.url} type="video/mp4" />
                      </video>
                    </li>
                  ) : (
                    <li
                      key={image.id}
                      onClick={() => {
                        setSelectedMedia(image);
                        setSelectedHashTags(image.hashtags);
                        setDeletedTags([]);
                      }}
                    >
                      <img src={image.url} alt="" />
                    </li>
                  )
                )}
            </ul>
            <div className="loadBtn">
              <Button
                primary
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                さらに読み込む
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {/* 選択した画像 */}
      {selectedMedia && (
        <Grid stackable>
          <GridRow columns={1}>
            <GridColumn>
              <Header as="h4">{selectedMedia.url}</Header>
            </GridColumn>
          </GridRow>
          <GridRow columns={1}>
            <GridColumn>
              <div className="media-wrap">
                {isVideo(selectedMedia.url) ? (
                  <video width="100%" controls>
                    <source src={selectedMedia.url} type="video/mp4" />
                  </video>
                ) : (
                  <Image src={selectedMedia.url} />
                )}
              </div>
            </GridColumn>
          </GridRow>
          <Grid.Row columns={1}>
            <Grid.Column className="hashtag-wrap">
              <p className="form-label">ハッシュタグ</p>
              <Grid>
                <GridRow columns={1}>
                  <GridColumn width={13}>
                    {selectedHashTags &&
                      selectedHashTags.map((selectedHashTag, index) => (
                        <Label key={index}>
                          {selectedHashTag.text}
                          <Icon
                            name="delete"
                            onClick={() => handleRemoveTag(selectedHashTag)}
                          />
                        </Label>
                      ))}
                  </GridColumn>
                  <GridColumn width={3}>
                    <div className="button-group">
                      <Button primary content="保存" onClick={saveHashTags} />
                    </div>
                  </GridColumn>
                </GridRow>
              </Grid>
              <GridRow>
                <GridColumn width={16}>
                  <Input
                    fluid
                    placeholder="タグを入力"
                    name="hashtags"
                    value={hashtagInput}
                    onChange={handleHashtagInputChange}
                    onKeyPress={handleHashtagInputKeyPress}
                  />
                  <Segment className="scrollable-container">
                    {filteredHashtags.map((hashtagMaster) => (
                      <Label
                        key={hashtagMaster.id}
                        as="a"
                        onClick={() => handleSelectedHashTags(hashtagMaster)}
                      >
                        {hashtagMaster.text}
                      </Label>
                    ))}
                  </Segment>
                </GridColumn>
              </GridRow>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
}

export default React.memo(VisualComponent);
