import React, { useState, useEffect, useCallback } from "react";
import {
  Icon,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
  Message,
  Loader,
  Button,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLoginCheck } from "../../hooks/useLoginCheck";

function RemoveCustomerListPage() {
  useLoginCheck();
  const [removeCustomers, setRemoveCustomers] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchRemoveCustomersData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_remove_customers/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sort_column: sortColumn,
          sort_type: sortType,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setRemoveCustomers(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
      setError("データの取得中にエラーが発生しました。");
    } finally {
      setLoading(false);
    }
  }, [sortColumn, sortType]);

  function handleColumnClick(col) {
    setSortColumn(col);
    setSortType(sortType === "desc" || !sortType ? "asc" : "desc");
  }

  useEffect(() => {
    setSortType("asc");
  }, [sortColumn]);

  useEffect(() => {
    fetchRemoveCustomersData();
  }, [sortColumn, sortType, fetchRemoveCustomersData]);

  const CustomTableHeaderCell = ({ title, columnName, width }) => {
    return (
      <TableHeaderCell
        width={width}
        onClick={() => {
          handleColumnClick(columnName);
        }}
      >
        {title}
        {columnName === sortColumn && sortType === "asc" && (
          <Icon name="triangle up" />
        )}
        {columnName === sortColumn && sortType === "desc" && (
          <Icon name="triangle down" />
        )}
      </TableHeaderCell>
    );
  };

  return (
    <>
      <div
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>退会者一覧</h1>

        {loading ? (
          <Loader active inline="centered" content="データを読み込み中..." />
        ) : error ? (
          <Message negative>
            <Message.Header>エラー</Message.Header>
            <p>{error}</p>
            <Button primary onClick={fetchRemoveCustomersData}>
              再読み込み
            </Button>
          </Message>
        ) : removeCustomers.length === 0 ? (
          <Message info>
            <Message.Header>データがありません</Message.Header>
            <p>退会者データが存在しません。</p>
          </Message>
        ) : (
          <Table celled inverted selectable>
            <TableHeader>
              <TableRow>
                <CustomTableHeaderCell
                  title={"顧客ID"}
                  columnName={"customer_id"}
                />
                <CustomTableHeaderCell
                  title={"日付"}
                  columnName={"created_at"}
                  width={2}
                />
                <CustomTableHeaderCell
                  title={"名前"}
                  columnName={"first_name_yomi"}
                  width={2}
                />
                <CustomTableHeaderCell
                  title={"理由"}
                  columnName={"reason"}
                  width={2}
                />
                <CustomTableHeaderCell
                  title={"詳細"}
                  columnName={"reason_detail"}
                />
                <CustomTableHeaderCell
                  title={"開始月"}
                  columnName={"start_month"}
                  width={2}
                />
              </TableRow>
            </TableHeader>

            <TableBody>
              {removeCustomers &&
                removeCustomers.map((removeCustomer) => (
                  <TableRow key={removeCustomer.id}>
                    <TableCell>{removeCustomer.customer_id}</TableCell>
                    <TableCell>{removeCustomer.created_at}</TableCell>
                    <TableCell>
                      {removeCustomer.first_name} {removeCustomer.last_name}
                    </TableCell>
                    <TableCell>{removeCustomer.reason}</TableCell>
                    <TableCell>{removeCustomer.reason_detail}</TableCell>
                    <TableCell>{removeCustomer.start_month}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </div>
    </>
  );
}

export default RemoveCustomerListPage;
