import React, { useState, useEffect } from "react";
import "../../../styles/mypage.css";

import { Icon, Input, Button, Checkbox, Form, Grid } from "semantic-ui-react";

function MenuPage() {
  const [menuItems, setMenuItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [createParentId, setCreateParentId] = useState(null);
  const [createDepth, setCreateDepth] = useState(0);

  useEffect(() => {
    getMenuItems();
  }, []);

  async function getMenuItems() {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/mypage/get_menu_items/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      console.log(data);
      setMenuItems(data);
    } catch (err) {
      console.error(err);
      alert("エラーが発生しました");
    }
  }

  // 項目選択時に呼び出される
  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setIsCreateModalOpen(false);
  };

  // 更新
  const handleUpdate = async (updatedItem) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/mypage/update_menu_item/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedItem),
      });
      if (!response.ok) throw new Error("保存に失敗");

      await getMenuItems();
      // setSelectedItem(null);
      alert("保存しました");
    } catch (err) {
      console.error(err);
      alert("エラーが発生しました");
    }
  };

  // 削除
  const handleDelete = async (deleteItem) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/mypage/delete_menu_item/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(deleteItem),
      });
      if (!response.ok) throw new Error("削除に失敗");

      await getMenuItems();
      setSelectedItem(null);
      alert("削除しました");
    } catch (err) {
      console.error(err);
      alert("エラーが発生しました");
    }
  };

  // 登録用フォーム表示
  const handleOpenCreateModal = (parentId = null, newDepth = 0) => {
    setCreateParentId(parentId);
    setCreateDepth(newDepth);
    setIsCreateModalOpen(true);
    setSelectedItem(null);
  };

  // 登録
  const handleCreate = async (newItem) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/mypage/create_menu_item/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newItem),
      });
      if (!response.ok) throw new Error("登録に失敗");

      await getMenuItems();
      setIsCreateModalOpen(false);
    } catch (err) {
      console.error(err);
      alert("エラーが発生しました");
    }
  };

  return (
    <>
      <section className="left-pane">
        <div style={{ marginBottom: "2em" }}>
          <Button
            primary
            content="新規作成"
            onClick={() => handleOpenCreateModal(null)}
          />
        </div>
        <MenuTree
          menuItems={menuItems}
          onSelectItem={handleSelectItem}
          onOpenCreateModal={handleOpenCreateModal}
        />
      </section>
      {selectedItem && (
        <section className="right-pane">
          <MenuItemEditor
            selectedItem={selectedItem}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
          />
        </section>
      )}

      {isCreateModalOpen && (
        <CreateMenuItemModal
          parentId={createParentId}
          parentDepth={createDepth}
          onCreate={handleCreate}
        />
      )}
    </>
  );
}

export default MenuPage;

// 左カラム
function MenuTree({ menuItems, onSelectItem, onOpenCreateModal }) {
  return (
    <div className="menu-tree">
      {menuItems.map((root) => (
        <MenuItemNode
          key={root.id}
          item={root}
          onSelectItem={onSelectItem}
          onOpenCreateModal={onOpenCreateModal}
        />
      ))}
    </div>
  );
}

function MenuItemNode({ item, onSelectItem, onOpenCreateModal, depth = 0 }) {
  const [expanded, setExpanded] = useState(true);

  // 大カテゴリかどうか
  const isLargeCategory = depth === 0 && item.is_category;
  // 小カテゴリ (深さ1以上 かつ is_category)
  const isSmallCategory = depth > 0 && item.is_category;
  // リンク (is_category=false)
  const isLink = !item.is_category;
  // 子要素があるかどうか
  const hasChildren = item.children && item.children.length > 0;

  // 親要素のクラス (コンテナ)
  let containerClass = "";
  if (isLargeCategory) {
    containerClass = "menu-large-category";
  } else if (isSmallCategory) {
    containerClass = "menu-small-category";
  } else if (isLink) {
    containerClass = "menu-link";
  }

  // 子要素 (node) 自体に付けたいクラスをさらに組み立て
  let nodeClass = "menu-item-node";
  if (isLargeCategory) {
    nodeClass += " menu-item-node-large"; // 大カテゴリ用
  } else if (isSmallCategory) {
    nodeClass += " menu-item-node-small"; // 小カテゴリ用
  } else if (isLink) {
    nodeClass += " menu-item-node-link"; // リンク用
  }

  const handleClick = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <div className={containerClass}>
      <div className={nodeClass} onClick={handleClick}>
        <div className="menu-box">
          <span>{item.name}</span>
          {item.badge && <span className="badge">{item.badge}</span>}

          <div className="icon-box">
            {item.is_category && (
              <Icon
                name="plus"
                className="plus-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenCreateModal(item.id, depth + 1);
                }}
              />
            )}

            <Icon
              name="edit"
              className="edit-icon"
              onClick={(e) => {
                e.stopPropagation();
                onSelectItem(item);
              }}
            />
          </div>
        </div>

        {/* 大カテゴリの場合はボタンを表示しない */}
        {!isLargeCategory && hasChildren && (
          <button className="toggle-button">{expanded ? "▼" : "▶"}</button>
        )}
      </div>

      {/* 大カテゴリの場合は常に展開, 小カテゴリの場合は expanded=true のときだけ展開 */}
      {hasChildren && (
        <div className="children">
          {(isLargeCategory || expanded) &&
            item.children.map((child) => (
              <MenuItemNode
                key={child.id}
                item={child}
                onSelectItem={onSelectItem}
                onOpenCreateModal={onOpenCreateModal}
                depth={depth + 1}
              />
            ))}
        </div>
      )}
    </div>
  );
}

// 右カラム
function MenuItemEditor({ selectedItem, onUpdate, onDelete }) {
  const [formData, setFormData] = useState({
    id: 0,
    name: "",
    url: "",
    is_category: false,
    badge: "",
    is_webview: false,
    order: 0,
    delete_flg: false,
  });

  useEffect(() => {
    if (selectedItem) {
      setFormData({
        id: selectedItem.id || 0,
        name: selectedItem.name || "",
        url: selectedItem.url || "",
        is_category: selectedItem.is_category || false,
        badge: selectedItem.badge || "",
        is_webview: selectedItem.is_webview || false,
        order: selectedItem.order || 0,
        delete_flg: selectedItem.delete_flg || false,
      });
    }
  }, [selectedItem]);

  // 入力
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // 更新
  const handleUpdateButton = () => {
    onUpdate({ ...selectedItem, ...formData });
  };

  // 更新
  const handleDeleteButton = () => {
    onDelete({ ...selectedItem, ...formData });
  };

  // 切り替え
  const handleToggle = async () => {
    setFormData((prev) => ({
      ...prev,
      is_webview: !prev.is_webview,
    }));
  };

  return (
    <div className="menu-item-editor">
      <div className="content-header">
        <h3></h3>
        <div className="button-group">
          {!formData.is_category && (
            <Form>
              <Form.Field>
                <label>WebView</label>
                <Checkbox
                  toggle
                  value={formData.is_webview}
                  checked={formData.is_webview}
                  onChange={handleToggle}
                />
              </Form.Field>
            </Form>
          )}
          <Button primary content="更新" onClick={handleUpdateButton} />
        </div>
      </div>
      <Grid container stackable>
        <Grid.Row columns={!formData.is_category ? 2 : 1}>
          {/* ラベル */}
          <Grid.Column>
            <p className="form-label">ラベル</p>
            <Input
              name="name"
              value={formData.name || ""}
              onChange={handleChange}
            />
          </Grid.Column>

          {/* バッジ */}
          {!formData.is_category && (
            <Grid.Column>
              <p className="form-label">バッジ</p>
              <Input
                name="badge"
                value={formData.badge || ""}
                onChange={handleChange}
              />
            </Grid.Column>
          )}
        </Grid.Row>

        {!formData.is_category && (
          <Grid.Row columns={1}>
            {/* URL */}
            <Grid.Column>
              <p className="form-label">URL</p>
              <Input
                name="url"
                value={formData.url || ""}
                onChange={handleChange}
              />
            </Grid.Column>
          </Grid.Row>
        )}

        <Grid.Row columns={1} className="delete-column">
          {/* 削除ボタン */}
          <Grid.Column>
            <Button color="red" content="削除" onClick={handleDeleteButton} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

// 新規作成用フォーム
function CreateMenuItemModal({ parentId, parentDepth, onCreate }) {
  const isParentSmallCat = parentDepth >= 1;
  const [formData, setFormData] = useState({
    parent: parentId,
    name: "",
    is_category: !isParentSmallCat,
    url: "",
    badge: "",
    is_webview: false,
    order: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e, { name, checked }) => {
    if (name === "is_category" && isParentSmallCat) {
      return;
    }
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSubmit = () => {
    onCreate(formData);
  };

  return (
    <section className="right-pane">
      <div className="menu-item-editor">
        <div className="content-header">
          <h3></h3>
          <div className="button-group">
            {/* WebView */}
            {!formData.is_category && (
              <Form>
                <Form.Field>
                  <label>WebView</label>
                  <Checkbox
                    toggle
                    name="is_webview"
                    checked={formData.is_webview}
                    onChange={handleCheckboxChange}
                  />
                </Form.Field>
              </Form>
            )}

            {/* カテゴリ */}
            {!isParentSmallCat && (
              <Form>
                <Form.Field>
                  <label>カテゴリ</label>
                  <Checkbox
                    toggle
                    name="is_category"
                    checked={formData.is_category}
                    onChange={handleCheckboxChange}
                  />
                </Form.Field>
              </Form>
            )}

            <Button primary content="登録" onClick={handleSubmit} />
          </div>
        </div>

        <Grid container stackable>
          <Grid.Row columns={!formData.is_category ? 2 : 1}>
            {/* ラベル */}
            <Grid.Column>
              <p className="form-label">ラベル</p>
              <Input
                name="name"
                value={formData.name || ""}
                onChange={handleInputChange}
              />
            </Grid.Column>

            {/* バッジ (リンクのみ) */}
            {!formData.is_category && (
              <Grid.Column>
                <p className="form-label">バッジ</p>
                <Input
                  name="badge"
                  value={formData.badge || ""}
                  onChange={handleInputChange}
                />
              </Grid.Column>
            )}
          </Grid.Row>

          {/* URL (リンクのみ) */}
          {!formData.is_category && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <p className="form-label">URL</p>
                <Input
                  name="url"
                  value={formData.url || ""}
                  onChange={handleInputChange}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    </section>
  );
}
