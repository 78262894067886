import React, { useState, useEffect } from "react";
import { useLoginCheck } from "../../hooks/useLoginCheck";
import { useLoading } from "../../contexts/LoadingContext";
import "../../styles/scss/openNotice.scss";

function OpenNoticePage() {
  useLoginCheck();
  const { setIsLoading } = useLoading();
  const [openNoticeRequestList, setOpenNoticeRequestList] = useState([]);

  // 予約開始リクエスト一覧取得
  const fetchOpenNoticeData = async () => {
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_open_notices/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setOpenNoticeRequestList(data.open_notices);
      console.log(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchOpenNoticeData();
  }, []);

  // メール送信
  const handleSendNotice = async (openNoticeRequest) => {
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/send_open_notice/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: openNoticeRequest.id,
          mail: openNoticeRequest.mail,
          studio_id: openNoticeRequest.studio_id,
          lesson_id: openNoticeRequest.lesson_id,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      console.log(data);
      alert("メールを送信しました");
      fetchOpenNoticeData();
    } catch (error) {
      console.error("送信中にエラーが発生しました:", error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <h1>予約開始リクエスト</h1>

      <div className="period">
        <div
          className="graph"
          style={{
            gridTemplateRows: `repeat(${openNoticeRequestList.length}, auto)`,
          }}
        >
          <ul style={{ gridRow: `span ${openNoticeRequestList.length}` }}>
            <li>メールアドレス</li>
            {openNoticeRequestList &&
              openNoticeRequestList.map((openNoticeRequest, index) => (
                <li>{openNoticeRequest.mail}</li>
              ))}
          </ul>
          <ul style={{ gridRow: `span ${openNoticeRequestList.length}` }}>
            <li>スタジオ</li>
            {openNoticeRequestList &&
              openNoticeRequestList.map((openNoticeRequest, index) => (
                <li>{openNoticeRequest.studio_name}</li>
              ))}
          </ul>
          <ul style={{ gridRow: `span ${openNoticeRequestList.length}` }}>
            <li>レッスン</li>
            {openNoticeRequestList &&
              openNoticeRequestList.map((openNoticeRequest, index) => (
                <li>{openNoticeRequest.lesson_name}</li>
              ))}
          </ul>
          <ul style={{ gridRow: `span ${openNoticeRequestList.length}` }}>
            <li>送信</li>
            {openNoticeRequestList &&
              openNoticeRequestList.map((openNoticeRequest, index) =>
                openNoticeRequest.done ? (
                  <li className="done" key={index}>
                    送信済み
                  </li>
                ) : openNoticeRequest.close_flg ? (
                  <li key={index}>定員</li>
                ) : (
                  <li key={index}>
                    <button
                      onClick={() => {
                        handleSendNotice(openNoticeRequest);
                      }}
                    >
                      送信
                    </button>
                  </li>
                )
              )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default OpenNoticePage;
