"use client";

import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  Header,
  Segment,
  Divider,
  Radio,
  Input,
  TextArea,
  Modal,
  Grid,
  Message,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "semantic-ui-css/semantic.min.css";
import { PREFECTURES } from "../../../constants/studioConstants";

function PushNotificationEditPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEditMode = !!id;

  // 都道府県でソートされた店舗リスト
  const [sortedStudios, setSortedStudios] = useState({});

  // フォームの状態
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    deliveryType: "immediate",
    scheduledAt: getNextTimeSlot(),
    actionType: "none",
    deepLink: "",
    webLink: "",
    targetType: "all",
    targetStudios: [],
  });

  const [minTime, setMinTime] = useState(new Date());
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    const updateMinTime = () => {
      const now = new Date();
      const nextSlot = getNextTimeSlot(now);
      setMinTime(nextSlot);
    };

    updateMinTime();
  }, [formData.scheduledAt]);

  // 次の15分枠を取得
  function getNextTimeSlot(baseTime = new Date()) {
    const now = new Date(baseTime);
    const minutes = now.getMinutes();
    const remainder = minutes % 15;
    const minutesToAdd = remainder === 0 ? 15 : 15 - remainder;
    const nextSlot = new Date(now.getTime() + minutesToAdd * 60000);
    nextSlot.setSeconds(0, 0); // 秒とミリ秒をリセット
    return nextSlot;
  }

  // 日付と時間の両方を考慮したminTimeの設定
  const getMinTimeForDate = (selectedDate) => {
    const currentDate = new Date();

    // 日付部分だけを比較するために新しいDateオブジェクトを作成
    const currentDateOnly = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );

    const selectedDateOnly = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate()
    );

    // 選択された日付が今日の場合は現在時刻から次の15分枠を計算
    if (selectedDateOnly.getTime() === currentDateOnly.getTime()) {
      return getNextTimeSlot();
    }

    // 選択された日付が今日以外の場合は00:00から選択可能
    return new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      0,
      0,
      0,
      0
    );
  };

  // 時間のフィルター
  const filterTime = (time) => {
    const currentDate = new Date();
    const selectedDate = formData.scheduledAt;

    // 日付部分だけを比較
    const currentDateOnly = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const selectedDateOnly = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate()
    );

    // 選択された日付が今日の場合のみ、現在時刻以降の時間をフィルタリング
    if (selectedDateOnly.getTime() === currentDateOnly.getTime()) {
      return time >= getNextTimeSlot(currentDate);
    }

    // 選択された日付が今日以外の場合はすべての時間を許可
    return true;
  };

  // 文字数カウント
  const [titleCount, setTitleCount] = useState(0);
  const [bodyCount, setBodyCount] = useState(0);

  // バリデーションエラー
  const [errors, setErrors] = useState({});

  // モーダル状態
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);

  // 編集モードの場合、データを取得
  useEffect(() => {
    const fetchNotificationData = async () => {
      if (isEditMode) {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(
            `${apiUrl}/api/mypage/get_push_notification/${id}/`
          );

          if (!response.ok) {
            throw new Error("通知の取得に失敗しました");
          }

          const data = await response.json();

          // APIのレスポンスをフォームデータの形式に変換
          const scheduledAtUTC = data.scheduled_at
            ? new Date(data.scheduled_at)
            : new Date();

          // 日本時間に調整（+9時間）
          const scheduledAtJST = new Date(
            scheduledAtUTC.getTime() + 9 * 60 * 60 * 1000
          );

          const formattedData = {
            title: data.title,
            content: data.content,
            deliveryType: data.delivery_type,
            scheduledAt: scheduledAtJST,
            actionType: data.action_type,
            deepLink: data.deep_link || "",
            webLink: data.web_link || "",
            targetType: data.target_type,
            targetStudios: data.target_studios || "",
          };

          setFormData(formattedData);
          setTitleCount(formattedData.title.length);
          setBodyCount(formattedData.content.length);
          setIsSent(data.is_sent);
        } catch (error) {
          console.error("通知データの取得中にエラーが発生しました:", error);
          alert("通知データの取得に失敗しました。");
        }
      }
    };

    fetchNotificationData();
  }, [isEditMode, id]);

  // 入力フィールドの変更ハンドラ
  const handleInputChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });

    // 文字数カウントの更新
    if (name === "title") {
      setTitleCount(value.length);
    } else if (name === "content") {
      setBodyCount(value.length);
    }

    // エラーをクリア
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  // 日付選択の変更ハンドラ
  const handleDateChange = (date) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const selectedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    // 選択された日付が今日の場合
    if (selectedDate.getTime() === today.getTime()) {
      const nextSlot = getNextTimeSlot();
      // 選択された時間が現在時刻より前の場合は、次の15分枠に設定
      if (
        date.getHours() < nextSlot.getHours() ||
        (date.getHours() === nextSlot.getHours() &&
          date.getMinutes() < nextSlot.getMinutes())
      ) {
        date = new Date(nextSlot);
      }
    }

    setFormData({ ...formData, scheduledAt: date });
  };

  // フォームの検証
  const validateForm = () => {
    const newErrors = {};

    if (!formData.title.trim()) {
      newErrors.title = "タイトルを入力してください";
    } else if (formData.title.length > 20) {
      newErrors.title = "タイトルは20文字以内で入力してください";
    }

    if (!formData.content.trim()) {
      newErrors.content = "本文を入力してください";
    } else if (formData.content.length > 100) {
      newErrors.content = "本文は100文字以内で入力してください";
    }

    if (formData.deliveryType === "scheduled" && !formData.scheduledAt) {
      newErrors.scheduledAt = "配信日時を選択してください";
    }

    if (formData.actionType === "deeplink" && !formData.deepLink.trim()) {
      newErrors.deepLink = "ディープリンクを入力してください";
    }

    if (formData.actionType === "weblink" && !formData.webLink.trim()) {
      newErrors.webLink = "WebページのURLを入力してください";
    }

    if (
      formData.targetType === "studio" &&
      formData.targetStudios.length === 0
    ) {
      newErrors.targetStudios = "店舗を選択してください";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // プレビューを表示
  const handlePreview = () => {
    if (validateForm()) {
      setPreviewModalOpen(true);
    }
  };

  // 送信処理
  const handleSend = () => {
    if (validateForm()) {
      setConfirmModalOpen(true);
    }
  };

  // 送信確認
  const confirmSend = async () => {
    if (!validateForm()) return;

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const endpoint = isEditMode
        ? `${apiUrl}/api/mypage/update_push_notification/${id}/`
        : `${apiUrl}/api/mypage/create_push_notification/`;

      // デバッグ用ログ
      console.log("画面表示時間（JST）:", formData.scheduledAt);
      console.log(
        "送信時間（UTC、9時間引いた）:",
        new Date(formData.scheduledAt.getTime() - 9 * 60 * 60 * 1000)
      );
      console.log(
        "送信時間（ISO形式）:",
        new Date(
          formData.scheduledAt.getTime() - 9 * 60 * 60 * 1000
        ).toISOString()
      );

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: formData.title,
          content: formData.content,
          delivery_type: formData.deliveryType,
          scheduled_at:
            formData.deliveryType === "scheduled"
              ? formData.scheduledAt.toISOString()
              : null,
          action_type: formData.actionType,
          deep_link:
            formData.actionType === "deeplink"
              ? formData.deepLink || null
              : null,
          web_link:
            formData.actionType === "weblink" ? formData.webLink || null : null,
          target_type: formData.targetType,
          target_studios:
            formData.targetType === "studio" ? formData.targetStudios : null,
        }),
      });

      if (!response.ok) {
        throw new Error(
          isEditMode ? "通知の更新に失敗しました" : "通知の登録に失敗しました"
        );
      }

      setConfirmModalOpen(false);
      navigate("/mypage/push-notification-list");
    } catch (error) {
      console.error(
        isEditMode
          ? "通知の更新中にエラーが発生しました:"
          : "通知の登録中にエラーが発生しました:",
        error
      );
      alert(
        isEditMode ? "通知の更新に失敗しました。" : "通知の登録に失敗しました。"
      );
    }
  };

  // キャンセル処理
  const handleCancel = () => {
    // フォームに変更があれば確認モーダルを表示
    setDiscardModalOpen(true);
  };

  // 変更を破棄して戻る
  const confirmDiscard = () => {
    setDiscardModalOpen(false);
    navigate("/mypage/push-notification-list");
  };

  // 店舗データの取得
  useEffect(() => {
    const fetchStudios = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/get_studios/`);

        if (!response.ok) {
          throw new Error("店舗データの取得に失敗しました");
        }

        const data = await response.json();
        const sorted = sortStudiosByPrefectureKey(data, PREFECTURES);
        setSortedStudios(sorted);
      } catch (error) {
        console.error("店舗データの取得中にエラーが発生しました:", error);
        alert("店舗データの取得に失敗しました。");
      }
    };

    // 地域ごとのグループ分け
    function groupStudiosByPrefecture(studios) {
      return studios.reduce((acc, studio) => {
        if (!acc[studio.prefecture]) {
          acc[studio.prefecture] = [];
        }
        acc[studio.prefecture].push(studio);
        return acc;
      }, {});
    }

    // 都道府県コードで並べ替え
    function sortStudiosByPrefectureKey(studios, prefectureOrder) {
      const grouped = groupStudiosByPrefecture(studios);
      const sortedGroup = {};
      prefectureOrder.forEach((prefecture) => {
        if (grouped[prefecture.text]) {
          sortedGroup[prefecture.text] = grouped[prefecture.text];
        }
      });
      return sortedGroup;
    }

    fetchStudios();
  }, []);

  // チェックボックス変更
  const handleStudioChange = (studioId) => {
    const newTargetStudios = formData.targetStudios.includes(studioId)
      ? formData.targetStudios.filter((id) => id !== studioId)
      : [...formData.targetStudios, studioId];

    setFormData({ ...formData, targetStudios: newTargetStudios });
  };

  // UTC時間を日本時間に変換して表示する関数
  function formatToJST(utcDateString) {
    if (!utcDateString) return "";

    // UTCの日時文字列をDateオブジェクトに変換
    const date = new Date(utcDateString);

    // 日本時間のフォーマットオプション
    const options = {
      timeZone: "Asia/Tokyo",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // 24時間表示
    };

    return new Intl.DateTimeFormat("ja-JP", options).format(date);
  }

  return (
    <div className="push-notification-edit">
      <Grid>
        <Grid.Column width={10}>
          <div className="form-section">
            <div className="page-header">
              <Header as="h1" className="page-title">
                {isEditMode ? "プッシュ通知編集" : "新規プッシュ通知作成"}
              </Header>
            </div>

            <Segment className="form-segment">
              <Form>
                <Form.Field error={!!errors.title}>
                  <label>
                    通知タイトル <span className="required">*</span>
                  </label>
                  <Input
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    placeholder="タイトルを入力（20文字以内）"
                    maxLength={20}
                    className="dark-input"
                    disabled={isSent}
                  />
                  <div className="character-count">
                    <span className={titleCount > 20 ? "error" : ""}>
                      {titleCount}/20
                    </span>
                  </div>
                  {errors.title && <Message error content={errors.title} />}
                </Form.Field>

                <Form.Field error={!!errors.content}>
                  <label>
                    通知本文 <span className="required">*</span>
                  </label>
                  <TextArea
                    name="content"
                    value={formData.content}
                    onChange={handleInputChange}
                    placeholder="本文を入力（100文字以内）"
                    rows={4}
                    maxLength={100}
                    className="dark-textarea"
                    disabled={isSent}
                  />
                  <div className="character-count">
                    <span className={bodyCount > 100 ? "error" : ""}>
                      {bodyCount}/100
                    </span>
                  </div>
                  {errors.content && <Message error content={errors.content} />}
                </Form.Field>

                <Divider />

                <Form.Field>
                  <label>
                    配信タイプ <span className="required">*</span>
                  </label>
                  <Form.Group inline>
                    <Form.Field>
                      <Radio
                        label="即時配信"
                        name="deliveryType"
                        value="immediate"
                        checked={formData.deliveryType === "immediate"}
                        onChange={handleInputChange}
                        className="dark-radio"
                        disabled={isSent}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="予約配信"
                        name="deliveryType"
                        value="scheduled"
                        checked={formData.deliveryType === "scheduled"}
                        onChange={handleInputChange}
                        className="dark-radio"
                        disabled={isSent}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form.Field>

                {formData.deliveryType === "scheduled" && (
                  <Form.Field error={!!errors.scheduledAt}>
                    <label>
                      配信日時 <span className="required">*</span>
                    </label>
                    <DatePicker
                      selected={formData.scheduledAt}
                      onChange={handleDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="yyyy/MM/dd HH:mm"
                      minDate={new Date()}
                      minTime={
                        formData.scheduledAt.getDate() === new Date().getDate()
                          ? getNextTimeSlot()
                          : new Date(0, 0, 0, 0, 0)
                      }
                      maxTime={new Date(0, 0, 0, 23, 45)}
                      filterTime={(time) => {
                        if (
                          formData.scheduledAt.getDate() !==
                          new Date().getDate()
                        )
                          return true;
                        const nextSlot = getNextTimeSlot();
                        return time >= nextSlot;
                      }}
                      className="form-control dark-datepicker"
                      disabled={isSent}
                    />
                    {errors.scheduledAt && (
                      <Message error content={errors.scheduledAt} />
                    )}
                  </Form.Field>
                )}

                {/* <Divider />

                <Form.Field>
                  <label>アクション設定</label>
                  <Form.Group inline>
                    <Form.Field>
                      <Radio
                        label="なし"
                        name="actionType"
                        value="none"
                        checked={formData.actionType === "none"}
                        onChange={handleInputChange}
                        className="dark-radio"
                        disabled={isSent}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="アプリ内画面へ遷移"
                        name="actionType"
                        value="deeplink"
                        checked={formData.actionType === "deeplink"}
                        onChange={handleInputChange}
                        className="dark-radio"
                        disabled={isSent}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="Webページを開く"
                        name="actionType"
                        value="weblink"
                        checked={formData.actionType === "weblink"}
                        onChange={handleInputChange}
                        className="dark-radio"
                        disabled={isSent}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form.Field>

                {formData.actionType === "deeplink" && (
                  <Form.Field error={!!errors.deepLink}>
                    <label>
                      ディープリンク <span className="required">*</span>
                    </label>
                    <Input
                      name="deepLink"
                      value={formData.deepLink}
                      onChange={handleInputChange}
                      placeholder="例: app://home/features"
                      className="dark-input"
                      disabled={isSent}
                    />
                    {errors.deepLink && (
                      <Message error content={errors.deepLink} />
                    )}
                  </Form.Field>
                )}

                {formData.actionType === "weblink" && (
                  <Form.Field error={!!errors.webLink}>
                    <label>
                      WebページURL <span className="required">*</span>
                    </label>
                    <Input
                      name="webLink"
                      value={formData.webLink}
                      onChange={handleInputChange}
                      placeholder="例: https://example.com/page"
                      className="dark-input"
                      disabled={isSent}
                    />
                    {errors.webLink && (
                      <Message error content={errors.webLink} />
                    )}
                  </Form.Field>
                )}

                <Divider />

                <Form.Field>
                  <label>
                    送信対象 <span className="required">*</span>
                  </label>
                  <Form.Group inline>
                    <Form.Field>
                      <Radio
                        label="全ユーザー"
                        name="targetType"
                        value="all"
                        checked={formData.targetType === "all"}
                        onChange={handleInputChange}
                        className="dark-radio"
                        disabled={isSent}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="特定店舗"
                        name="targetType"
                        value="studio"
                        checked={formData.targetType === "studio"}
                        onChange={handleInputChange}
                        className="dark-radio"
                        disabled={isSent}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form.Field>

                {formData.targetType === "studio" && (
                  <Form.Field error={!!errors.targetStudios}>
                    <label>
                      店舗選択 <span className="required">*</span>
                    </label>
                    <div className="studio-checkboxes">
                      {Object.entries(sortedStudios).map(
                        ([prefecture, studioList]) => (
                          <div key={prefecture} className="prefecture-group">
                            <Header as="h4">{prefecture}</Header>
                            <div className="studio-group">
                              {studioList.map((studio) => (
                                <Form.Checkbox
                                  key={studio.id}
                                  label={studio.name}
                                  checked={formData.targetStudios.includes(
                                    studio.id
                                  )}
                                  onChange={() => handleStudioChange(studio.id)}
                                  className="dark-checkbox"
                                  disabled={isSent}
                                />
                              ))}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    {errors.targetStudios && (
                      <Message error content={errors.targetStudios} />
                    )}
                  </Form.Field>
                )}

                <Divider /> */}

                <div className="form-actions">
                  {!isSent && (
                    <Button
                      type="button"
                      onClick={handleCancel}
                      className="cancel-button"
                    >
                      キャンセル
                    </Button>
                  )}
                  <Button
                    type="button"
                    onClick={handlePreview}
                    className="preview-button"
                  >
                    プレビュー
                  </Button>
                  {!isSent && (
                    <Button
                      type="button"
                      color="green"
                      onClick={handleSend}
                      className="send-button"
                    >
                      {isEditMode
                        ? "更新"
                        : formData.deliveryType === "immediate"
                        ? "送信"
                        : "保存"}
                    </Button>
                  )}
                </div>
              </Form>
            </Segment>
          </div>
        </Grid.Column>

        <Grid.Column width={6}>
          <div className="preview-section">
            <Segment className="device-preview">
              <div className="device-frame">
                <div className="device-status-bar">
                  <div className="time">9:41</div>
                </div>
                <div className="notification-preview">
                  <div className="app-icon"></div>
                  <div className="notification-content">
                    <div className="notification-header">
                      <div className="app-name">アプリ名</div>
                      <div className="notification-time">今</div>
                    </div>
                    <div className="notification-title">
                      {formData.title || "通知タイトル"}
                    </div>
                    <div className="notification-content">
                      {formData.content || "通知本文がここに表示されます"}
                    </div>
                  </div>
                </div>
              </div>
            </Segment>
          </div>
        </Grid.Column>
      </Grid>

      {/* プレビューモーダル */}
      <Modal
        open={previewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
        size="small"
        className="preview-modal"
      >
        <Modal.Header>通知プレビュー</Modal.Header>
        <Modal.Content>
          <div className="modal-preview">
            <div className="notification-modal-preview">
              <div className="app-icon"></div>
              <div className="notification-content">
                <div className="notification-header">
                  <div className="app-name">アプリ名</div>
                  <div className="notification-time">今</div>
                </div>
                <div className="notification-title">{formData.title}</div>
                <div className="notification-content">{formData.content}</div>
              </div>
            </div>

            <Divider />

            <div className="notification-details">
              <p>
                <strong>配信タイプ:</strong>{" "}
                {formData.deliveryType === "immediate"
                  ? "即時配信"
                  : "予約配信"}
              </p>
              {formData.deliveryType === "scheduled" && (
                <p>
                  <strong>配信日時:</strong>{" "}
                  {new Intl.DateTimeFormat("ja-JP", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                    timeZone: "Asia/Tokyo",
                  }).format(formData.scheduledAt)}
                </p>
              )}
              <p>
                <strong>アクション:</strong>{" "}
                {formData.actionType === "none"
                  ? "なし"
                  : formData.actionType === "deeplink"
                  ? "アプリ内画面へ遷移"
                  : formData.actionType === "weblink"
                  ? "Webページを開く"
                  : "アプリを起動"}
              </p>
              {formData.actionType === "deeplink" && (
                <p>
                  <strong>ディープリンク:</strong> {formData.deepLink}
                </p>
              )}
              {formData.actionType === "weblink" && (
                <p>
                  <strong>WebページURL:</strong> {formData.webLink}
                </p>
              )}
              <p>
                <strong>送信対象:</strong>{" "}
                {formData.targetType === "all" ? "全ユーザー" : "特定店舗"}
              </p>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setPreviewModalOpen(false)}>閉じる</Button>
        </Modal.Actions>
      </Modal>

      {/* 送信確認モーダル */}
      <Modal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        size="tiny"
        className="confirm-modal"
      >
        <Modal.Header>
          {formData.deliveryType === "immediate" ? "送信確認" : "保存確認"}
        </Modal.Header>
        <Modal.Content>
          <p>
            {formData.deliveryType === "immediate"
              ? "この通知を送信しますか？"
              : `この通知を ${new Intl.DateTimeFormat("ja-JP", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                  timeZone: "Asia/Tokyo",
                }).format(formData.scheduledAt)} に予約登録しますか？`}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setConfirmModalOpen(false)}>
            キャンセル
          </Button>
          <Button positive onClick={confirmSend}>
            {isEditMode
              ? "更新"
              : formData.deliveryType === "immediate"
              ? "送信"
              : "保存"}
          </Button>
        </Modal.Actions>
      </Modal>

      {/* 変更破棄確認モーダル */}
      <Modal
        open={discardModalOpen}
        onClose={() => setDiscardModalOpen(false)}
        size="tiny"
        className="discard-modal"
      >
        <Modal.Header>変更を破棄</Modal.Header>
        <Modal.Content>
          <p>変更内容が保存されていません。変更を破棄して戻りますか？</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setDiscardModalOpen(false)}>キャンセル</Button>
          <Button negative onClick={confirmDiscard}>
            破棄して戻る
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default PushNotificationEditPage;
